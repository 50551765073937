import React, { useState, useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "./AutocompleteDropdown.css"; // Import the CSS file

export default function CustomInputAutocomplete() {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const history = useHistory(); // Get the history object

    useEffect(() => {
        let active = true;

        if (inputValue === "") {
            setOptions([]);
            return undefined;
        }

        setLoading(true);

        (async () => {
            try {
                const { data } = await axios.get(`/api/v1/products?keyword=${inputValue}`);
                if (active) {
                    const productNames = data.products.map((product) => product.name);
                    setOptions(productNames);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [inputValue]);

    const handleOptionSelect = (event, value) => {
        if (value) {
            history.push(`/products/${value}`);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter" && inputValue.trim()) {
            history.push(`/products/${inputValue}`);
        } else if (event.key === "Enter") {
            history.push("/products");
        }
    };

    return (
        <Autocomplete
            sx={{
                width: 200,
            }}
            id="custom-input-demo"
            options={options}
            onInputChange={(event, value) => setInputValue(value)}
            onChange={handleOptionSelect} // Handle the option selection
            loading={loading}
            renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                    <input
                        type="text"
                        {...params.inputProps}
                        onKeyPress={handleKeyPress} // Handle Enter key press
                        placeholder="Search..."
                        style={{
                            padding: "10px",
                            margin: "10px",
                            height: "25px",
                            backgroundColor: "white",
                            border: "none",
                            color: "rgba(0, 0, 0, 0.637)",
                            width: "100%",
                            outline: "none",
                        }}
                    />
                    {loading && (
                        <CircularProgress
                            size={20}
                            style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                marginTop: "-10px",
                            }}
                            className="autocomplete-loading-spinner"
                        />
                    )}
                </div>
            )}
        />
    );
}