import React, { Fragment,  useEffect,useState} from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeFromWishlist, getWishlist } from "../../actions/wishlistAction";
import { addItemsToCart } from "../../actions/cartAction";
import "./Wishlist.css"; // Import the CSS file for styling


const Wishlist = ({item}) => {
  const [quantity] = useState(1);
  const dispatch = useDispatch();
  const { loading, error, wishlistItems } = useSelector((state) => state.wishlist);

  useEffect(() => {
    dispatch(getWishlist());
  }, [dispatch]);

  const removeFromWishlistHandler = (productId) => {
    dispatch(removeFromWishlist(productId));
  };


  const addToCartHandler = (productId) => {
    dispatch(addItemsToCart(productId, quantity));
    alert("Item Added To Cart Successfully !!..");
  };

  return (
    <div className="wishlist-page">
      <h5 className="wishlist-page-title">
        My Wishlist</h5>
      {loading ? (
        <p> Loading.. </p>
      ) : error ? (
        <h4 variant="error">{error}</h4>
      ) : wishlistItems?.length === 0 ? (
        <h4>Your wishlist is empty. <Link to="/products" className="link-back-to-shopping">Go back to shopping</Link></h4>
      ) : (
        <Fragment>
          <div className="cartPage">
            <div className="cartHeader">
            </div>

            {wishlistItems.map((item) => (
              <div className="cartContainer" key={item._id}> 
                <div className="wishlist-item">
                  <Link to={`/product/${item._id}`} className="wishlist-item-image">
                  <img src={item.images[0].url} alt={item.name} />
                  </Link>
                  <Link to={`/product/${item._id}`} className="wishlist-item-name">
                    {item.name}
                  </Link>


                  <div className="wishlist-item-actions">
                <button className="gobtn" onClick={()=> addToCartHandler(item._id)} >
                   Move to Cart
                </button>
                  <button className="wishlist-item-remove" 
                    onClick={() => removeFromWishlistHandler(item._id)} >
                    Remove from wishlist
                  </button>
                  </div>
                
                </div>
              </div>
            ))}
          </div>
        </Fragment>
      )}
    </div>
  );
};


export default Wishlist;
