import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, getProduct } from "../../actions/productAction";
import Loader from "../layout/Loader/Loader";
import ProductCard from "../Home/ProductCard";
import Pagination from "react-js-pagination";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import Button from "@material-ui/core/Button";
import "./Products.css";
import { GoChevronRight } from "react-icons/go";
import { GoChevronLeft } from "react-icons/go";

const categories = [
  "Earrings",
    "Necklaces",
    "Chains",
    "Bracelets", 
    "Bangles",
    "Mangalsutra",
    "Anklets",
    "Nose Pins & Rings",
    "Accessories",
    "Hand Rings",
    "Toe Rings"
];

const priceRanges = [
  { label: "All Prices", value: [0, 250000] },
  { label: "₹100-₹200", value: [100, 200] },
  { label: "₹1000-₹2000", value: [1000, 2000] },
  { label: "₹5000-₹10000", value: [5000, 10000] },
  { label: "₹10,000 - ₹20,000", value: [10000, 20000] },
  { label: "₹20,000 - ₹30,000", value: [20000, 30000] },
  { label: "Above ₹1,00,000", value: [100000, 250000] },
];

const ratingOptions = [
  { label: "All Ratings", value: 0 },
  { label: "5 Stars", value: 5 },
  { label: "4 Stars & Above", value: 4 },
  { label: "3 Stars & Above", value: 3 },
  
];

function escapeString(str) {
  return encodeURIComponent(str);
}

const Products = ({ match }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [currentPage, setCurrentPage] = useState(1);
  const [price, setPrice] = useState([0, 250000]);
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [ratings, setRatings] = useState(0);
  const [subcategories, setSubcategories] = useState([]);

  const {
    products,
    loading,
    error,
    productsCount,
    resultPerPage,
    filteredProductsCount,
  } = useSelector((state) => state.products);

  const keyword = match.params.keyword;

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct(keyword, currentPage, price, escapeString(category), escapeString(subcategory), ratings));
  }, [dispatch, keyword, currentPage, price, category, subcategory, ratings, alert, error]);

  const setCurrentPageNo = (e) => {
    setCurrentPage(e);
  };

  const [selectedPriceRangeLabel, setSelectedPriceRangeLabel] = useState("");

  const priceHandler = (event) => {
    const selectedPriceRangeLabel = event.target.value;
    const selectedPriceRange = priceRanges.find(range => range.label === selectedPriceRangeLabel).value;
    setSelectedPriceRangeLabel(selectedPriceRangeLabel);
    setPrice(selectedPriceRange);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    setSubcategory(""); // Reset subcategory when category changes
    const fetchedSubcategories = getSubcategories(event.target.value);
    setSubcategories(fetchedSubcategories);
  };

  const handleSubcategoryChange = (event) => {
    setSubcategory(event.target.value);
  };

  const getSubcategories = (category) => {
    switch (category) {
      case "Earrings":
        return  ["Stud Earrings","Hoop Earrings","Drop Earrings", "Jhumka Earrings","Chandbali Earrings"];
      case "Necklaces":
        return ["Pearl Necklaces","Pendant Necklaces","Layered Necklaces","Statement Necklaces","princess necklace","Diamond Necklace"];
        case "Chains":
          return ["Rolo chain","Link chain","Ball chain","Beaded chain","Silver chain","Rope chain","Bar chain","Spiral chain","Spiga chain"];
        case "Bracelets":
        return ["Chain bracelets","Charm bracelets","Link bracelets","Slap bracelets","Stretch Bracelets","Braided bracelets","Leather bracelets"];
        case "Bangles":
          return ["Polki bangles","Gemstone kada bangles","Delicate charm bangles","Ruby bangles","Kundan bangles","Silk bangles"];
      case "Mangalsutra":
        return ["Traditional Mangalsutra", "Modern Mangalsutra","Diamond Mangalsutra","Flower Mangalsutra","Rose Mangalsutra"];
      case "Anklets":
        return ["Traditional Anklets", "Modern Anklets","Gold anklets","Silver Anklets","Leather anklets/fabric anklets","Bead anklets","Charm anklets","Gemstone anklets"];
      case "Nose Pins & Rings":
        return ["Stud Nose Pins", "Hoop Nose Rings", "Traditional Nose Rings", "Clip-on Nose Pins","Twist nose ring","Nose ring bone","L-nose rings","Clicker nose ring"];
      case "Accessories":
        return ["Mang Tikka","Waist Chains","Toe Rings","Armlets"];
      case "Hand Rings":
        return["Engagement Ring","Couple Rings","Thumb Ring","Dailywear Ring","Puzzle Ring","Gemstone Ring"];
        case "Toe Rings":
          return["Full circle leaf toe ring","Half circle leaf toe ring"];
        default:
        return [];
    }
  };

  const handleResetFilters = () => {
    setCurrentPage(1);
    setPrice([0, 250000]);
    setCategory("");
    setSubcategory("");
    setRatings(0);
    dispatch(getProduct(keyword, 1, [0, 25000], "", "", 0));
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="PRODUCTS -- ECOMMERCE" />
          <div className="filterBox">
          <select value={selectedPriceRangeLabel} onChange={priceHandler} className="priceSelect">
              {priceRanges.map((range) => (
                <option className="option" key={range.label} value={range.label}>
                  {range.label}
                </option>
              ))}
            </select>

            <select value={category} onChange={handleCategoryChange} className="categorySelect">
              <option value="">All Categories</option>
              {categories.map((cat) => (
                <option key={cat} value={cat}>{cat}</option>
              ))}
            </select>

            {category && subcategories.length > 0 && (
              <Fragment>
                <select value={subcategory} onChange={handleSubcategoryChange} className="subcategorySelect">
                  <option value="">All Subcategories</option>
                  {subcategories.map((subcat) => (
                    <option key={subcat} value={subcat}>{subcat}</option>
                  ))}
                </select>
              </Fragment>
            )}

            <select value={ratings} onChange={(e) => setRatings(Number(e.target.value))} className="ratingsSelect">
              {ratingOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            <Button variant="contained" color="secondary" onClick={handleResetFilters}>
              Reset Filters
            </Button>
          </div>

          <div className="products">
            <h2 className="productsHeading" style={{ position: 'relative', zIndex: 100, backgroundColor: '#E0CBC4', boxShadow: '#E0CBC0' }}>Products</h2>
            <div className="container1">
              <div className="container">
                {products && products.map((product) => (
                  <ProductCard key={product._id} product={product} />
                ))}
              </div>
            </div>
          </div>

          {resultPerPage < filteredProductsCount && (
            <div className="paginationBox">
              <Pagination 
                activePage={currentPage}
                itemsCountPerPage={resultPerPage}
                totalItemsCount={productsCount}
                onChange={setCurrentPageNo}
                nextPageText={<GoChevronRight />}
                prevPageText={<GoChevronLeft />}
                itemClass="page-item"
                linkClass="page-link"
                activeClass="pageItemActive"
                activeLinkClass="pageLinkActive"
              />
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Products;
