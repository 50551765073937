import "./App.css";
import { useEffect } from "react";
import Header from "./component/layout/Header/Header.js";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import WebFont from "webfontloader";
import React from "react";
import Footer from "./component/layout/Footer/Footer";
import Home from "./component/Home/Home";
import ProductDetails from "./component/Product/ProductDetails";
import Products from "./component/Product/Products";
import EarRings from "./component/CategoryPage/Earrings.js";
import Necklaces from "./component/CategoryPage/Necklaces.js";
import Bracelets from "./component/CategoryPage/Bracelets.js";
import Bangles from "./component/CategoryPage/Bangles.js";
import Mangalsutra from "./component/CategoryPage/Mangalsutra.js";
import NoseRings from "./component/CategoryPage/NoseRings.js";
import Accessories from "./component/CategoryPage/Accessories.js"
import Rings from "./component/CategoryPage/Rings.js";
import Search from "./component/Product/Search";
import LoginSignUp from "./component/User/LoginSignUp";
import store from "./store";
import { loadUser } from "./actions/userAction";
import UserOptions from "./component/layout/Header/UserOptions";
import { useSelector } from "react-redux";
import Profile from "./component/User/Profile";
import ProtectedRoute from "./component/Route/ProtectedRoute";
import UpdateProfile from "./component/User/UpdateProfile";
import UpdatePassword from "./component/User/UpdatePassword";
import ForgotPassword from "./component/User/ForgotPassword";
import ResetPassword from "./component/User/ResetPassword";
import Cart from "./component/Cart/Cart";
import Shipping from "./component/Cart/Shipping";
import ConfirmOrder from "./component/Cart/ConfirmOrder";
/*import axios from "axios";*/
import Payment from "./component/Cart/Payment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import OrderSuccess from "./component/Cart/OrderSuccess";
import MyOrders from "./component/Order/MyOrders";
import OrderDetails from "./component/Order/OrderDetails";
import Dashboard from "./component/Admin/Dashboard.js";
import ProductList from "./component/Admin/ProductList.js";
import NewProduct from "./component/Admin/NewProduct";
import UpdateProduct from "./component/Admin/UpdateProduct";
import OrderList from "./component/Admin/OrderList";
import ProcessOrder from "./component/Admin/ProcessOrder";
import UsersList from "./component/Admin/UsersList";
import UpdateUser from "./component/Admin/UpdateUser";
import ProductReviews from "./component/Admin/ProductReviews";
import Contact from "./component/layout/Contact/Contact";
import About from "./component/layout/About/About";
/*import NotFound from "./component/layout/Not Found/NotFound";*/
import AddCouponForm from "./component/Admin/AddCouponForm.js";
import Wishlist from "./component/Wishlist/Wishlist.js"
import Address from "./component/User/Address.js";
import CategoryPage from "./component/Home/CategoryPage.js";
import TermsOfUse from "./component/layout/FooterExtn/Terms.js";
import PrivacyPolicy from "./component/layout/FooterExtn/PrivacyPolicy.js";
import RefundPolicy from "./component/layout/FooterExtn/RefundPolicy.js";
import ShippingPolicy from "./component/layout/FooterExtn/ShippingPolicy.js";
import FAQ from "./component/layout/FooterExtn/FAQ.js";
import CouponList from "./component/Admin/CouponList";
import OurStory from "./component/layout/FooterExtn/OurStory.js";
import Blogs from "./component/layout/FooterExtn/Blogs.js";
import bangle from "./component/layout/FooterExtn/bangle.js";
import GuidanceArticle from "./component/layout/FooterExtn/GuidanceArticle.js";
import NewsLetter from "./component/layout/FooterExtn/NewsLetter.js";
import Bridal from "./component/layout/FooterExtn/Bridal.js";
import OfficeSpecial from "./component/layout/FooterExtn/OfficeSpecial.js";
import JGuide from "./component/layout/FooterExtn/JGuide.js";
import RGuide from "./component/layout/FooterExtn/RGuide.js";
import BGuide from "./component/layout/FooterExtn/BGuide.js";
// import CouponList from "./component/Admin/CouponList.js";

function App() {
  const { isAuthenticated, user } = useSelector((state) => state.user);

  // const [stripeApiKey, setStripeApiKey] = useState("");
  //
  // async function getStripeApiKey() {
  //   const { data } = await axios.get("/api/v1/stripeapikey");
  //
  //   setStripeApiKey(data.stripeApiKey);
  // }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto", "Droid Sans", "Chilanka"],
      },
    });

    store.dispatch(loadUser());

    // getStripeApiKey();
  }, []);

  // TODO: Making stripe key static to allow rendering of payment component without login
  //  else after login app needs reload to load stripe key and load payment component.
  const stripeApiKey = "pk_test_51OFW4PSGyHAQNLht8HKF7GZyEG1ne1KApj8e0tyFO6OsQcL6lsO8rqv5zcmKjSWeIYQiHXhZZiZGSSnev6hCkZUo00fMlwvT8Q";

  // window.addEventListener("contextmenu", (e) => e.preventDefault());

  return (
    <div className="wrapper">
      <div className="content">
        <Router>
          <Header />

          {isAuthenticated && <UserOptions user={user} />}

          {stripeApiKey && (
            <Elements stripe={loadStripe(stripeApiKey)}>
              <ProtectedRoute
                exact
                path="/process/payment"
                component={Payment}
              />
            </Elements>
          )}

          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/product/:id" component={ProductDetails} />
            <Route exact path="/products" component={Products} />
            <Route path="/products/:keyword" component={Products} />

            <Route exact path="/search" component={Search} />

            <Route exact path="/contact" component={Contact} />

            <Route exact path="/about" component={About} />

            <ProtectedRoute exact path="/account" component={Profile} />
            <Route path="/ear-rings" component={EarRings} />
            <Route path="/necklaces"component = {Necklaces}/>
            <Route exact path="/bracelets" component={Bracelets} />
            <Route path="/bangles" component = {Bangles}/>
            <Route path="/mangalsutra" component = {Mangalsutra}/>
            <Route path="/nose-rings" component = {NoseRings} />
            <Route path="/rings" component={Rings}/>
            <Route path="/accessories" component={Accessories}/>
            <ProtectedRoute exact path="/me/update" component={UpdateProfile} />

            <ProtectedRoute
              exact
              path="/password/update"
              component={UpdatePassword}
            />

            <Route exact path="/password/forgot" component={ForgotPassword} />

            <Route
              exact
              path="/password/reset/:token"
              component={ResetPassword}
            />

            <Route exact path="/login" component={LoginSignUp} />

            <ProtectedRoute exact path="/cart" component={Cart} />

            <Route exact path="/wishlist" component={Wishlist} />

            <ProtectedRoute exact path="/address" component={Address} />

            <ProtectedRoute exact path="/shipping" component={Shipping} />

            <ProtectedRoute exact path="/success" component={OrderSuccess} />

            <ProtectedRoute exact path="/orders" component={MyOrders} />

            <ProtectedRoute
              exact
              path="/order/confirm"
              component={ConfirmOrder}
            />

            <ProtectedRoute exact path="/order/:id" component={OrderDetails} />

            <ProtectedRoute
              isAdmin={true}
              exact
              path="/admin/dashboard"
              component={Dashboard}
            />
            <ProtectedRoute
              exact
              path="/admin/products"
              isAdmin={true}
              component={ProductList}
            />
            <ProtectedRoute
              exact
              path="/admin/product"
              isAdmin={true}
              component={NewProduct}
            />

            <ProtectedRoute
              exact
              path="/admin/product/:id"
              isAdmin={true}
              component={UpdateProduct}
            />
            <ProtectedRoute
              exact
              path="/admin/orders"
              isAdmin={true}
              component={OrderList}
            />

            <ProtectedRoute
              exact
              path="/admin/order/:id"
              isAdmin={true}
              component={ProcessOrder}
            />
            <ProtectedRoute
              exact
              path="/admin/users"
              isAdmin={true}
              component={UsersList}
            />

            <ProtectedRoute
              exact
              path="/admin/user/:id"
              isAdmin={true}
              component={UpdateUser}
            />

            <ProtectedRoute
              exact
              path="/admin/reviews"
              isAdmin={true}
              component={ProductReviews}
            />
            <ProtectedRoute
              exact
              path="/admin/coupons"
              isAdmin={true}
              component={AddCouponForm}
            />

<ProtectedRoute
              exact
              path="/admin/couponsall"
              isAdmin={true}
              component={CouponList}
            />
            

            <Route
              exact
              path="/api/v1/product/category/:category"
              component={CategoryPage}
            />
            <Route exact path="/RefundPolicy" component={RefundPolicy} />
            <Route exact path="/shippingPolicy" component={ShippingPolicy}/>
            <Route exact path="/FAQ" component={FAQ}/>
            <Route exact path="/terms" component={TermsOfUse} />
            <Route exact path="/privacypolicy" component={PrivacyPolicy} />
            <Route exact path="/OurStory" component={OurStory} />
            
            <Route exact path="/Blogs" component={Blogs} />
            <Route exact path="/GuidanceArticle" component={GuidanceArticle} />
          
            <Route exact path="/bangle" component={bangle}/>
            <Route exact path="/NewsLetter" component={NewsLetter}/>

            <Route exact path="/Bridal" component={Bridal}/>
            <Route exact path="/JGuide" component={JGuide}/>
            <Route exact path="/RGuide" component={RGuide}/>
            <Route exact path="/BGuide" component={BGuide}/>


            <Route exact path="/OfficeSpecial" component={OfficeSpecial}/>


            
            
            
            

          </Switch>

          <Footer />
        </Router>
      </div>
    </div>
  )
}

export default App;
