import React from "react";
import "./sidebar.css";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import {  TreeItem } from "@material-ui/lab";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AddIcon from "@material-ui/icons/Add";
import ListAltIcon from "@material-ui/icons/ListAlt";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import RateReviewIcon from "@material-ui/icons/RateReview";
import LoyaltyIcon from "@material-ui/icons/Loyalty";


const Sidebar = () => {
  return (
    <div className="sidebar">
      <Link to="/">
        <p style={{color:"black",font: "600 2rem Roboto",  padding: "2rem"}}>Ecommerce</p>
      </Link>
      <Link to="/admin/dashboard">
        <p>
          <DashboardIcon /> Dashboard
        </p>
      </Link>
      <Link to="/admin/products">
              <TreeItem nodeId="2" label="All Products" icon={<PostAddIcon />} />
            </Link>
            <Link to="/admin/product">
              <TreeItem nodeId="3" label="Create Product" icon={<AddIcon />} />
            </Link>
      
      <Link to="/admin/orders">
        <p>
          <ListAltIcon />
          Orders
        </p>
      </Link>
      <Link to="/admin/users">
        <p>
          <PeopleIcon /> Users
        </p>
      </Link>
      <Link to="/admin/reviews">
        <p>
          <RateReviewIcon />
          Reviews
        </p>
      </Link>
      <Link to="/admin/coupons">
        <p>
          <LoyaltyIcon /> Add Coupon Form
        </p>
      </Link>
      <Link to="/admin/couponsall">
        <p>
          <LoyaltyIcon /> All Coupons
        </p>
      </Link>
      
    </div>
  );
};

export default Sidebar;                                                                                                                     