import React from 'react';
import './Blogs.css';
import bangles from '../About/Images/bangles.jpg';
import ring from '../About/Images/ring.jpg';
import necklace from '../About/Images/necklace.jpg';
import mangal from '../About/Images/mangal.jpg';
import toering from '../About/Images/toering.jpg';


const Blogs = () => {
  return (
    <div className='content1'>
      <h1>Our Blogs</h1>
      <div className="bangle-container">
        <div className="bangle-text">
          <h3>1.Why Bangles ?</h3>
          <p>
    The tradition of Indian women wearing bangles started in ancient times.  
    Women in India wear bangles to enhance their feminine grace and beauty.  
    The word bangle has been derived from the traditional word <b> Bangri or Bangali</b>, 
    which in Sanskrit these words mean <b>‘the ornament that adorns the arm’</b>.
Bangles are ornaments by both unmarried women and married women in India. 
 Historically, there is strong evidence that Indian women have been adorning their 
 arms with bangles since ancient times.  One of the oldest art objects in India, 
 the bronze figurine of a dancing girl excavated at Mohenjo Daro epitomizes the antiquity and 
 the universality of wrist ornaments in India.  The figurine stands in the nude with one arm 
 at her hip, the other arm completely weighted down with a collection of bangles. <br></br>
            
          </p>
        </div>
        <img className="bangle-image" src={bangles} alt="Bangles" />
      </div>
      
      <div className="rings">
        <img className="rings-image" src={ring} alt="Ring" />
        <div className="rings-text">
          <h3>2.Why Rings ?</h3>
          <p>
          Ring It is the most common ornament worn by both men and women. 
          The nerves of our body are connected to each other and metal is considered good 
          for health. Ring finger has a nerve which is connected to the heart through the brain.
           Thumb rings are said to stimulate the happy hormones.Some believe that gold rings 
           on the index fingers are connected to the solar plexus chakra and the metal element
            in traditional Chinese medicine. This combination is thought to enhance confidence,
             personal power, and the flow of energy throughout the body.

          </p>
        </div>
      </div>

      <div className='necklace'>
        <div className='necklace-container'>
        <div className='necklace-text'>
          <h3>3.Why Necklace ?</h3>
          <p>
          Necklaces are the most integral part of the bridal trousseau and add the highest
           level of elegance to a bride. Scientifically, a necklace helps to regularize blood flow,
            blood pressure, and pulse rate as it is worn near the heart. It controls emotions
             and strengthens love. Wearing a necklace with many semi precious stones is believed to 
             bind one selves with eternal powers it is also believed to protect oneself from all sorts
              of evil powers.Jewelry with clasps, such as necklaces, may have been among the earliest forms
               of jewelry ever known to men and women. It may have been the first form of jewelry
                ever made by ancient civilizations to demonstrate power, rank, and wealth.
          </p> 
        </div>
        <img className='necklace-image' src={necklace} alt="necklace"/>
        </div>
      </div>

      <div className='mangalsutra'>
        <img className='mangal-images' src={mangal} alt={mangal} width="300px" height="300px"/>
        <div className='mangal-text'>
          <h3>4.Why Mangalyam ?</h3>
          <p>
          In Mangalsutra, the groom ties three knots around the bride's neck in the fashion
           of a necklace using black and gold beads and a holy thread called a Thaali, or
            Mangalyam, which signifies the eternal bond between the bride and the groom. 
            In the Mangalsutra, the necklace represents the bride's life and welfare.
            The knots
The groom ties three knots in the Mangalyam around the bride's neck, each with its own
 significance: <br></br>
Manasa knot: The groom's commitment to the bride <br></br>
Vaacha knot: The commitment between the bride and groom's families <br></br>
Karmena knot: The groom's family's promise to care for and protect the bride <br></br> 
          </p>
        </div>
      </div>

      <div className='toe-rings'>
        <div className='toe-rings-container'>
          <div className='toe-ring-text'>
            <h3>5.Why Toe-Rings ?</h3>
            <p>
            Toe rings have been believed to be worn to regulate the menstrual cycle, and
             thus increase the chances of conception, as the slight pressure on the second toe 
             ensures a healthy uterus. Some cultures also say that the pressure felt on the 
             second toe helps to ease pain during intercourse.In Indian culture, toe rings are
              seen as a symbol of a woman's marital status and are worn as a sign of commitment 
              to her husband. Additionally, toe rings are believed to have a cooling effect on
               the body, which is especially beneficial during hot summers.
            </p>
          </div>
          <img className='toe-ring-image' src={toering} alt={toering}></img>

        </div>
      </div>
    </div>
  );
};

export default Blogs;
