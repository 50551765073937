import React from 'react';
import "./terms.css"


const TermsOfUse = () => {
  return (
    <div className="paragraph-page">
      <h2>Terms and Conditions</h2>
    <p>
    Welcome to  Borcelle Jewellery!! 
    Please read these Terms of Use carefully before using Borcelle Jewellery
     operated by Infispec. These Terms govern your access to and use of the Website, 
     including any content, functionality, and services offered on or through the Website. <br></br><br></br>

    <b> 1. Acceptance of Terms </b><br></br>
  By accessing or using our website, you agree to comply with and be bound by these Terms.
   If you do not agree with any part of these Terms, please do not use the Website. <br></br>
   <b> 2. Eligibility </b><br></br>
  You must be at least 18 years old or have the legal capacity to enter into a binding 
  agreement in your jurisdiction to use this Website. By using the Website,
  you represent and warrant that you meet these eligibility requirements.  <br></br>
 
 <b> 3. Account Registration </b><br></br>
To access certain features of the Website, you may be required to register an account. 
You are responsible for maintaining the confidentiality of your account information, 
including your password, and for all activities that occur under your account. 
You agree to notify us immediately of any unauthorized use of your account or any other
 breach of security. <br></br>

<b>4. Intellectual Property </b><br></br>
All content on this Website, including but not limited to text, graphics, logos, images, and 
software, is the property of Infispec or its content suppliers and is protected by 
international copyright, trademark, and other intellectual property laws. You may not use, 
reproduce, distribute, or create derivative works based on this content without our prior 
written consent. <br></br>

<b>5. Product Information </b><br></br>
We strive to provide accurate and up-to-date information about our products. However, we do 
not warrant that the product descriptions, prices, or other content available on the Website 
are accurate, complete, reliable, current, or error-free. If a product offered by us is not 
as described, your sole remedy is to return it in unused condition. <br></br>

<b>6. Orders and Payment </b><br></br>
By placing an order through the Website, you agree to provide accurate and complete 
information and to pay the total amount of your order, including any applicable taxes and 
shipping fees. We reserve the right to refuse or cancel any order at our sole discretion. <br></br>

<b>7. Shipping and Delivery </b><br></br>
We will make every effort to ship your order promptly. However, shipping and delivery times 
may vary depending on your location and the availability of the products. We are not 
responsible for any delays or damages caused by shipping carriers. <br></br>

<b>8. Returns and Refunds </b><br></br>
Please review our Return and Refund Policy for information on how to return a product and
 request a refund. We reserve the right to refuse a return or refund if the product does
  not meet the conditions outlined in our policy. <br></br>

<b> 9. Limitation of Liability </b><br></br>
To the fullest extent permitted by law, we shall not be liable for any direct, indirect, 
incidental, special, consequential, or punitive damages arising out of or relating to your
 use of the Website, or the products purchased through the Website. <br></br>

<b>10. Indemnification </b><br></br>
You agree to indemnify, defend, and hold harmless Infispec and its affiliates, officers,
 directors, employees, and agents from and against any claims, liabilities, damages, losses, 
 and expenses, including reasonable attorneys' fees, arising out of or related to your use of
  the Website or violation of these Terms.<br></br> 

 <b>11. Modifications to Terms </b><br></br>
We may revise these Terms from time to time at our sole discretion. Any changes will be 
effective immediately upon posting the revised Terms on the Website. Your continued use of the
 Website following the posting of revised Terms constitutes your acceptance of those changes.<br></br> 

<b>12. Governing Law </b><br></br>
These Terms shall be governed by and construed in accordance with the laws of India, 
without regard to its conflict of law principles. <br></br>

<b>13. Contact Us </b><br></br>
If you have any questions or concerns about these Terms, please contact us at
 contact@infispec.in   <br></br>

    </p>
    </div>
  )
};

export default TermsOfUse;
