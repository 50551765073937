import React from 'react'
import './Bridal.css';
import bride from '../About/Images/bride.jpg';

const Bridal = () => {
  return (
    <div className='bride'>
      <img  className='bride-img'src={bride} alt={bride}/><br></br><br></br>
        <div className='bride-text'>
            <p> <b>Bridal Jewelry Collection :</b><br></br>
   Your wedding day is one of the most special moments in your life, and our Bridal Jewelry 
   collection is designed to complement the beauty and elegance of that day. At Brocelle, 
   we offer a range of exquisitely crafted pieces that will make you shine as you walk down
    the aisle.<br></br><br></br>

    At Brocelle, we understand that every wedding is a celebration of love, and our Bridal 
    Jewelry collection is designed to adorn both brides and grooms with pieces that complement 
    their unique styles. Our collection is thoughtfully divided into <b> Women’s Bridal Jewelry</b> and 
    <b>Men’s Wedding Jewelry</b>, ensuring that every couple finds the perfect jewelry for their special
     day.<br></br><br></br>


   <b>  Women’s Bridal Jewelry</b><br></br><br></br>
    Our Women’s Bridal Collection is crafted to make you feel elegant and radiant. Whether you 
    prefer timeless classics or modern designs, we have something for every bride.<br></br><br></br>

   <b>Bridal Necklaces: </b>Featuring diamonds, gold, and precious gemstones, our necklaces are perfect
   for creating that luxurious bridal look.<br></br>

   <b>Earrings:</b>  From delicate studs to ornate chandeliers, these earrings add a touch of grace to 
   your bridal outfit.<br></br>

    <b>Maang Tikkas & Matha Pattis:</b> Traditional, yet contemporary pieces to accentuate your 
    bridal attire.<br></br>

     <b>Bangles & Bracelets:</b> Complete your look with intricately designed bangles, available in gold, 
kundan, and diamonds.<br></br>

  <b>Rings & Nose Rings:</b> Stunning pieces that symbolize love and tradition.<br></br><br></br>

  To Explore more womens jewellery <a href="/Products">Click Here !</a><br></br><br></br>


 <b> Men’s Wedding Jewelry</b><br></br><br></br>
  Our Men’s Wedding Jewelry Collection is designed with modern grooms in mind, offering a blend 
  of elegance and tradition. From classic to contemporary, we have the perfect pieces to complete
   the groom’s ensemble.<br></br><br></br>

  <b>Cufflinks: </b> Add a refined touch to your outfit with our selection of
   elegant cufflinks.<br></br>

  <b>Bracelets:</b> Whether gold, silver, or gemstone-studded, our men’s bracelets offer 
  sophistication and style.<br></br>

  <b>Chains & Pendants:</b> Simple yet striking pieces, perfect for adding a touch of class
   to your wedding attire.<br></br>

  <b>Brooches:</b> Our selection of brooches complements traditional Indian wear like 
  sherwanis, enhancing your look with elegance.<br></br>

 <b>Rings: </b> Explore our collection of rings crafted in gold, diamonds, and platinum, 
 perfect for your wedding day and beyond.<br></br><br></br>

 To Explore more mens jewellery <a href="/Products">Click Here !</a><br></br><br></br>



     </p>
        </div>
      
    </div>
  )
}

export default Bridal
