import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaUser, FaShoppingCart, FaBars, FaTimes, FaHeart } from "react-icons/fa";
import logo from "../../../images/Infispec-logo2.png";
import "./Navbar.css";
import AutocompleteDropdown from "../../Product/AutocompleteDropdown";

const Navbar = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const toggleSearchBar = () => {
    setIsSearchBarVisible(!isSearchBarVisible);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const navbar = document.querySelector('.navbar');
      if (navbar) {
        navbar.classList.toggle('hidden', prevScrollPos < currentScrollPos);
      }
      prevScrollPos = currentScrollPos;
    };

    let prevScrollPos = window.pageYOffset;
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-container"> 
        <div className="navbar-left">
            <Link to="/" className="navbar-logo">
            <img src={logo} alt="Logo" style={{ height: '90px', width: '220px' }} />
            </Link>
          <div className={`navbar-links ${isNavbarOpen ? "active" : ""}`}>
            <Link to="/" onClick={toggleNavbar}>Home</Link>
            <Link to="/products" onClick={toggleNavbar}>Products</Link>
            <Link to="/about" onClick={toggleNavbar}>About</Link>
          </div>
        </div>
        
        <div className="navbar-right">
          <div className={`navbar-icons ${isNavbarOpen ? "active" : ""}`}>
            {isSearchBarVisible && (
              <div className="search-bar">
                <AutocompleteDropdown />
              </div>
            )}
            <div className="icon" onClick={toggleSearchBar}>
              <FaSearch />
            </div>
            <Link to="/login" className="icon" onClick={toggleNavbar}>
              <FaUser />
            </Link>
            <Link to="/wishlist" className="icon" onClick={toggleNavbar}>
              <FaHeart />
            </Link>
            <Link to="/cart" className="icon" onClick={toggleNavbar}>
              <FaShoppingCart />
            </Link>
          </div>
          <div className="navbar-toggle" onClick={toggleNavbar}>
            {isNavbarOpen ? <FaTimes /> : <FaBars />}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
