import React, { Fragment, useEffect, useState } from "react";
import "./newProduct.css";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, createProduct } from "../../actions/productAction";
import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import MetaData from "../layout/MetaData";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import DescriptionIcon from "@material-ui/icons/Description";
import StorageIcon from "@material-ui/icons/Storage";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import SideBar from "./Sidebar";
import { NEW_PRODUCT_RESET } from "../../constants/productConstants";

const NewProduct = ({ history }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const { loading, error, success } = useSelector((state) => state.newProduct);

  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [costprice, setCostPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [Stock, setStock] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [netPrice, setNetPrice] = useState(0);
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);

 const categories = [
    "Earrings",
    "Necklaces",
    "Chains",
    "Bracelets", 
    "Bangles",
    "Mangalsutra",
    "Anklets",
    "Nose Pins & Rings",
    "Accessories",
    "Hand Rings",
    "Toe Rings"
    
  ];

  const subcategoriesMap = {
    "Earrings": ["Stud Earrings","Hoop Earrings","Drop Earrings", "Jhumka Earrings","Chandbali Earrings"],
    "Necklaces": ["Pearl Necklaces","Pendant Necklaces","Layered Necklaces","Statement Necklaces","princess necklace","Diamond Necklace"],
    "Chains":["Rolo chain","Link chain","Ball chain","Beaded chain","Silver chain","Rope chain","Bar chain","Spiral chain","Spiga chain"],
    "Bracelets": ["Chain bracelets","Charm bracelets","Link bracelets","Slap bracelets","Stretch Bracelets","Braided bracelets","Leather bracelets"],
    "Bangles":["Polki bangles","Gemstone kada bangles","Delicate charm bangles","Ruby bangles","Kundan bangles","Silk bangles"],
    "Mangalsutra": ["Traditional Mangalsutra", "Modern Mangalsutra","Diamond Mangalsutra","Flower Mangalsutra","Rose Mangalsutra"],
    "Anklets": ["Traditional Anklets", "Modern Anklets","Gold anklets","Silver Anklets","Leather anklets/fabric anklets","Bead anklets","Charm anklets","Gemstone anklets"],
    "Nose Pins & Rings": ["Stud Nose Pins", "Hoop Nose Rings", "Traditional Nose Rings", "Clip-on Nose Pins","Twist nose ring","Nose ring bone","L-nose rings","Clicker nose ring"],
    "Accessories": ["Mang Tikka","Waist Chains","Toe Rings","Armlets"],
    "Hand Rings":["Engagement Ring","Couple Rings","Thumb Ring","Dailywear Ring","Puzzle Ring","Gemstone Ring"],
    "Toe Rings":["Full circle leaf toe ring","Half circle leaf toe ring"]
    
 
    // Add more subcategories for other categories
  };


  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Product Created Successfully");
      history.push("/admin/dashboard");
      dispatch({ type: NEW_PRODUCT_RESET });
    }

    const calculatedNetPrice = price - (price * (discount / 100));
    setNetPrice(calculatedNetPrice);
  }, [dispatch, alert, error, history, success, price, discount]);

  const createProductSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("price", price);
    myForm.set("description", description);
    myForm.set("category", category);
    myForm.set("subcategory", subcategory);
    myForm.set("Stock", Stock);
    myForm.set("discount", discount);
    myForm.set("netPrice", netPrice);
    myForm.set("costprice", costprice);

    images.forEach((image) => {
      myForm.append("images", image);
    });
    dispatch(createProduct(myForm));
  };

  const createProductImagesChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);
    setImagesPreview([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((old) => [...old, reader.result]);
          setImages((old) => [...old, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  return (
    <Fragment>
      <MetaData title="Create Product" />
      <div className="dashboard">
        <SideBar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={createProductSubmitHandler}
          >
            <h1>Create Product</h1>

            <div>
              <SpellcheckIcon />
              <input
                type="text"
                placeholder="Product Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <AttachMoneyIcon />
              <input
                type="number"
                placeholder="Price"
                required
                value={price || ""}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>

            <div>
              <AttachMoneyIcon />
              <input
                type="number"
                placeholder="Cost Price"
                required
                value={costprice || ""}
                onChange={(e) => setCostPrice(e.target.value)}
              />
            </div>

            <div>
              <AttachMoneyIcon />
              <input
                type="number"
                placeholder="Discount Percentage"
                value={discount || ""}
                onChange={(e) => setDiscount(e.target.value)}
              />
            </div>

            <div>
              <AttachMoneyIcon />
              <input
                type="number"
                placeholder="Net Price"
                value={netPrice || ""} // Ensure it doesn't show '0' by setting an empty string when no name is available
                readOnly
              />
            </div>

            <div>
              <DescriptionIcon />
              <textarea
                placeholder="Product Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>

            <div>
              <AccountTreeIcon />
              <select onChange={(e) => setCategory(e.target.value)}>
                <option value="">Choose Category</option>
                {categories.map((cate) => (
                  <option key={cate} value={cate}>
                    {cate}
                  </option>
                ))}
              </select>
            </div>
            {category && subcategoriesMap[category] && (
              <div>
                <AccountTreeIcon />
                <select onChange={(e) => setSubcategory(e.target.value)}>
                  <option value="">Choose Subcategory</option>
                  {subcategoriesMap[category].map((subcat) => (
                    <option key={subcat} value={subcat}>
                      {subcat}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div>
              <StorageIcon />
              <input
                type="number"
                placeholder="Stock"
                required
                value={Stock}
                onChange={(e) => setStock(e.target.value)}
              />
            </div>

            <div id="createProductFormFile">
              <input
                type="file"
                name="avatar"
                accept="image/*"
                onChange={createProductImagesChange}
                multiple
              />
            </div>

            <div id="createProductFormImage">
              {imagesPreview.map((image, index) => (
                <img key={index} src={image} alt="Product Preview" />
              ))}
            </div>

            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              Create
            </Button>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default NewProduct;
