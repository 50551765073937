import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import axios from "axios";
import { addAddress, editAddress, removeAddress } from "../../actions/userAction";
import { useAlert } from "react-alert";
import './Address.css';

const Address = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { user } = useSelector((state) => state.user);

  const [newAddress, setNewAddress] = useState({
    address: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    phoneNo: "",
  });

  const [editMode, setEditMode] = useState(false);
  const [editAddressId, setEditAddressId] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    // Fetch countries when the component mounts
    axios.get("https://restcountries.com/v3.1/all")
      .then(response => {
        const countryOptions = response.data.map(country => ({
          label: country.name.common,
          value: country.cca2, // or use country.cca3 for 3-letter country codes
        }));
        setCountries(countryOptions);
      })
      .catch(error => {
        alert.error("Failed to load countries");
      });
  }, [alert]);

  useEffect(() => {
    if (editMode) {
      const selectedAddress = user.addresses.find(address => address._id === editAddressId);
      if (selectedAddress) {
        setNewAddress(selectedAddress);
        if (selectedAddress.country) {
          fetchStates(selectedAddress.country);
        }
      }
    } else {
      setNewAddress({
        address: "",
        city: "",
        state: "",
        country: "",
        pinCode: "",
        phoneNo: "",
      });
    }
  }, [editMode, editAddressId, user.addresses]);

  const fetchStates = (countryCode) => {
    axios.get(`https://api.countrystatecity.in/v1/countries/${countryCode}/states`, {
      headers: {
        "X-CSCAPI-KEY": "NmtzTXdGNFdPMWlGSFFRQ3BFNnVrd254OW1aMnV2ZmpRTm8wSnhxag==", // replace with your actual API key
      },
    })
      .then(response => {
        const stateOptions = response.data.map(state => ({
          label: state.name,
          value: state.iso2,
        }));
        setStates(stateOptions);
      })
      .catch(error => {
        alert.error("Failed to load states");
      });
  };

  const handleInputChange = (e) => {
    setNewAddress({ ...newAddress, [e.target.name]: e.target.value });
  };

  const handleCountryChange = (selectedCountry) => {
    setNewAddress({ ...newAddress, country: selectedCountry.value, state: "" });
    fetchStates(selectedCountry.value);
  };

  const handleStateChange = (selectedState) => {
    setNewAddress({ ...newAddress, state: selectedState.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !newAddress.address ||
      !newAddress.city ||
      !newAddress.state ||
      !newAddress.country ||
      !newAddress.pinCode ||
      !newAddress.phoneNo
    ) {
      alert.error("Please fill in all address fields");
      return;
    }
    if (editMode) {
      dispatch(editAddress(editAddressId, newAddress));
      setEditMode(false);
      alert.success("Address edited successfully");
    } else {
      dispatch(addAddress(newAddress));
      alert.success("Address added successfully");
    }
  };

  const handleEdit = (addressId) => {
    setEditMode(true);
    setEditAddressId(addressId);
  };

  const handleDelete = (addressId) => {
    dispatch(removeAddress(addressId));
    alert.success("Address deleted successfully");
    window.location.reload();
  };

  const handleButtonClick = () => {
    window.location.reload();
  };

  return (
    <div className="address-container">
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <h2>{editMode ? "EDIT ADDRESS" : "ADD ADDRESS"}</h2>
          <div>
            <label htmlFor="address">Address:</label><br></br>
            <input
              type="text"
              id="address"
              name="address"
              value={newAddress.address}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="city">City:</label><br></br>
            <input
              type="text"
              id="city"
              name="city"
              value={newAddress.city}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="country">Country:</label>
            <Select
              id="country"
              options={countries}
              value={countries.find(c => c.value === newAddress.country)}
              onChange={handleCountryChange}
            />
          </div>
          <div>
            <label htmlFor="state">State:</label>
            <Select
              id="state"
              options={states}
              value={states.find(s => s.value === newAddress.state)}
              onChange={handleStateChange}
              isDisabled={!newAddress.country}
            />
          </div>
          <div>
            <label htmlFor="pinCode">Pin Code:</label><br></br>
            <input
              type="text"
              id="pinCode"
              name="pinCode"
              value={newAddress.pinCode}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="phoneNo">Phone Number:</label><br></br>
            <input
              type="text"
              id="phoneNo"
              name="phoneNo"
              value={newAddress.phoneNo}
              onChange={handleInputChange}
            />
          </div>
          <button type="submit" onClick={handleButtonClick}>{editMode ? "Edit Address" : "Add Address"}</button>
        </form>
      </div>
      <div className="saved-addresses-container">
        <h2>SAVED ADDRESSES</h2>
        <ul>
          {user.addresses.map((address) => (
            <li key={address._id}>
              <p>
                <strong>Address:</strong> {address.address}
                <br />
                <strong>City:</strong> {address.city}
                <br />
                <strong>State:</strong> {address.state}
                <br />
                <strong>Country:</strong> {address.country}
                <br />
                <strong>Pin Code:</strong> {address.pinCode}
                <br />
                <strong>Phone Number:</strong> {address.phoneNo}
              </p>
              <button onClick={() => handleEdit(address._id)}>Edit</button>
              <button onClick={() => handleDelete(address._id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Address;
