import React, { Fragment } from "react";
import { Typography, Stepper, StepLabel, Step, makeStyles } from "@material-ui/core";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import "./CheckoutSteps.css";

const useStyles = makeStyles((theme) => ({
  stepper: {
    boxSizing: "border-box",
    marginTop: theme.spacing(20), // Adjust the spacing as needed
  },
  stepLabel: {
    color: "rgba(0, 0, 0, 0.649)",
    "&.Mui-active": {
      color: "tomato",
    },
    "&.Mui-completed": {
      color: "tomato",
    },
  },
}));

const CheckoutSteps = ({ activeStep }) => {
  const classes = useStyles();

  const steps = [
    {
      label: <Typography>Shipping Details</Typography>,
      icon: <LocalShippingIcon />,
    },
    {
      label: <Typography>Confirm Order</Typography>,
      icon: <LibraryAddCheckIcon />,
    },
    {
      label: <Typography>Payment</Typography>,
      icon: <AccountBalanceIcon />,
    },
  ];

  return (
    <Fragment>
      <Stepper alternativeLabel activeStep={activeStep} className={classes.stepper}>
        {steps.map((item, index) => (
          <Step
            key={index}
            active={activeStep === index}
            completed={activeStep >= index}
          >
            <StepLabel
              className={classes.stepLabel}
              icon={item.icon}
            >
              {item.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Fragment>
  );
};

export default CheckoutSteps;
