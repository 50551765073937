import React from 'react'
import "./OurStory.css";
import craft from '../About/Images/craft.jpg';
import art from '../About/Images/art.jpg';
import design from '../About/Images/design.jpg';

const OurStory = () => {
  return (
    <div className='story-head'>
      <h3>Our Story</h3>
      <div className='story-text'>
        <p>Welcome to Borcelle Jewelry, where craftsmanship meets storytelling. 
          Founded with a passion for timeless beauty and artistry, Borcelle was born from a 
          simple dream: to create jewelry that is more than just an accessory—it’s a reflection
           of individuality, memories, and moments that last a lifetime.<br></br>

           Our journey began with a love for the intricate world of jewelry design, a
            sketchbook full of ideas, and a small studio where each piece was brought to life 
            by hand. From day one, we knew Borcelle had to be different. Our vision wasn’t just 
            to create jewelry, but to craft pieces that tell a story—pieces that resonate with
             our customers on a personal level.<br></br><br></br>

             <b>Craftsmanship and Design:</b><br></br>
             <img className='craft' src={craft} alt={craft}/><br></br>
             Every Borcelle piece is designed with the belief that jewelry should be both 
             beautiful and meaningful. We combine traditional craftsmanship with contemporary 
             designs, creating collections that range from minimalist elegance to bold statement
              pieces. Each collection draws inspiration from art, nature, history, and personal 
              experiences, making every piece a unique treasure.<br></br>
              
              At Borcelle, we take pride in offering more than just jewelry; we offer a piece of
               art that reflects the wearer’s personal journey. Our custom designs, from engraved 
               pieces to personalized birthstone collections, allow you to create jewelry that 
               speaks to your heart.  <br></br><br></br>

              <b> The Borcelle Experience :</b><br></br>
              At the core of Borcelle is a commitment to creating an unforgettable experience 
              for every customer. From the moment you discover our collections to the day you 
              receive your piece, we want your experience to be exceptional. We’ve carefully 
              crafted every detail, from our elegant eco-friendly packaging to the personal 
              touches that make each piece feel like your very own.<br></br>
              
              We’ve built a community of customers who share our love for meaningful jewelry.
               Our story is reflected in every design, and we invite you to become part of it. 
               Whether you’re looking for a one-of-a-kind piece or a collection that celebrates 
               your individuality, Borcelle is here to help you find jewelry that tells your story.<br></br><br></br>


            <b> 1.The Art of Storytelling Through Jewelry: </b><br></br>
            <img className='art' src={art} alt={art}/><br></br>
            <b>How Borcelle Brings Meaning to Every Piece :</b><br></br>
            In a world where trends come and go, at Borcelle Jewelry, we believe that true style 
            is timeless. Our pieces are designed not only to adorn but to tell stories—stories 
            of love, personal milestones, and cherished memories. Each piece in our collection 
            is more than just jewelry; it’s a reflection of who you are and what matters most 
            to you.<br></br><br></br>

            <b>Why Storytelling Matters in Jewelry:</b><br></br>
            Jewelry has always played a symbolic role in human history. Whether it’s a family
             heirloom passed down through generations or a custom-made necklace to celebrate a
              personal achievement, every piece of jewelry carries its own story. At Borcelle,
               we take that tradition to heart. From initial sketches to the final product,
                we design with intention, ensuring that every detail is infused with meaning.<br></br><br></br>

            <b>Personalization for Your Story:</b><br></br>
            What makes Borcelle stand out is the way we offer personalized pieces that let 
            you express your unique journey. Whether it’s a birthstone ring that celebrates your
             family or an engraved bracelet with a special date, our custom options allow you
              to create jewelry that tells your story. We love hearing from our customers about 
              the significance behind the pieces they choose, and we take pride in being part of
               your personal moments.<br></br><br></br>

              <b> Jewelry That Grows With You:</b><br></br>
              As you evolve, so do the stories your jewelry tells. Borcelle collections are 
              designed to grow with you, offering timeless designs that can be passed down 
              through generations. We want you to look at your Borcelle pieces years from now 
              and remember the moments they represent.<br></br><br></br>

             <b>2.Behind the Scenes The Craftsmanship That Defines Borcelle Jewelry: </b> <br></br>
             <img className='design' src={design} alt={design}/><br></br>
             In the world of fast fashion and mass-produced accessories, true craftsmanship is
              becoming harder to find. At Borcelle Jewelry, we are committed to keeping the art
               of traditional jewelry-making alive, while infusing each piece with modern elegance
                and sophistication. So what exactly goes into creating a Borcelle piece? Let us 
                take you behind the scenes.<br></br><br></br>

               <b> From Inspiration to Design :</b><br></br>
               Every Borcelle collection starts with inspiration, whether it's drawn from nature, 
               art, or the personal stories that shape our lives. We take these ideas and
                translate them into designs that are both timeless and contemporary. Our 
                design process is meticulous, as we carefully consider every element—proportion, 
                balance, and wearability—to create jewelry that feels just as good as it looks.<br>
                </br><br></br>
                
              Once the designs are finalized, we work with skilled artisans who bring our visions
               to life. Our team is made up of expert craftspeople who understand that precision 
               is key to creating a piece that lasts. They work with fine metals and ethically 
               sourced gemstones to ensure each piece is of the highest quality.<br></br><br></br>
               
               <b>The Human Touch in Every Piece:</b><br></br>
               One thing that sets Borcelle apart is our commitment to the human touch. 
               Each piece is handcrafted, which means that no two pieces are exactly alike. 
               This level of attention to detail and care is what makes Borcelle jewelry truly 
               special. From hand-setting each stone to polishing the final product, our artisans
                pour their skill and dedication into every step of the process.<br></br><br></br>

            <b>A Legacy of Quality:</b><br></br>
            At Borcelle, we believe that fine jewelry is an investment—one that should last for 
            years and even be passed down through generations. That’s why we focus on creating
             pieces with enduring beauty and strength. We use high-quality materials and 
             traditional techniques, ensuring that your Borcelle jewelry will be a part of your 
             life’s most important moments for years to come.<br></br><br></br>

             <b>Looking Forward:</b><br></br>
              As we continue to grow, we’re excited to explore new designs, innovative
               technologies, and sustainable practices. Our dream is to open a flagship store
                where customers can not only shop but also immerse themselves in the world of 
                Borcelle—experiencing firsthand the craftsmanship and artistry behind our pieces.<br></br><br></br>
                
                We’re excited to continue this journey with you and to help you discover jewelry that’s as
                 unique as your story.In Borcelle Jewelry, where timeless beauty and personal
                  connection are at the heart of everything we do.
             We invite you to discover the craftsmanship behind our collections and find pieces
              that will become your lifelong treasures.we believe that jewelry isn’t just something you wear—it’s something 
              you live in. Explore our collections and discover pieces that speak to you, pieces 
              that will tell your story for years to come.<br></br><br></br>
           </p>
      </div>
    </div>
  )
}

export default OurStory
