import React from 'react'
import './RGuide.css';

const RGuide = () => {
  return (
    <div className='rguide'>
       <h2>Ring Size Guide</h2>
Accurately measuring your ring size contributes to comfort, aesthetics, and the longevity of the
piece, making it an essential step in purchasing or wearing a ring. Below are steps mentioned to
accurately measure you ring size:<br></br><br></br>

<b>1.Gather Materials:</b><br></br>
-A strip of paper or a piece of string<br></br>
-A ruler<br></br>
-Scissors<br></br>
-A pen<br></br><br></br>
<b>2.Wrap the Paper/String:</b><br></br>
Wrap the paper strip or string around the base of your finger where you plan to wear the ring.
Ensure it’s snug but not too tight; you should be able to slide it over your knuckle.<br></br><br></br>

<b>3.Mark the Overlap:</b><br></br>
Use a pen to mark where the paper or string overlaps.<bR></bR><br></br>

<b>4.Measure the Length:</b><br></br>
Lay the strip of paper or string flat and measure the length from the starting end to your mark in
millimeters.<br></br><br></br>


<b>5.Use a Size Chart:</b><br></br>
Convert the measurement to ring size using a size chart. A measurement of approximately 51.9
mm corresponds to a size 6 in the US.<br></br>

<div class="ring-size-guide">
  <table>
    <thead>
      <tr>
        <th>Ring Size (US)</th>
        <th>Circumference (mm)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>3</td>
        <td>44.2</td>
      </tr>
      <tr>
        <td>3.5</td>
        <td>45.5</td>
      </tr>
      <tr>
        <td>4</td>
        <td>46.8</td>
      </tr>
      <tr>
        <td>4.5</td>
        <td>48.0</td>
      </tr>
      <tr>
        <td>5</td>
        <td>49.3</td>
      </tr>
      <tr>
        <td>5.5</td>
        <td>50.6</td>
      </tr>
      <tr>
        <td>6</td>
        <td>51.9</td>
      </tr>
      <tr>
        <td>6.5</td>
        <td>53.1</td>
      </tr>
      <tr>
        <td>7</td>
        <td>54.4</td>
      </tr>
      <tr>
        <td>7.5</td>
        <td>55.7</td>
      </tr>
      <tr>
        <td>8</td>
        <td>57.0</td>
      </tr>
      <tr>
        <td>8.5</td>
        <td>58.3</td>
      </tr>
      <tr>
        <td>9</td>
        <td>59.5</td>
      </tr>
      <tr>
        <td>9.5</td>
        <td>60.8</td>
      </tr>
      <tr>
        <td>10</td>
        <td>62.1</td>
      </tr>
      <tr>
        <td>10.5</td>
        <td>63.4</td>
      </tr>
      <tr>
        <td>11</td>
        <td>64.6</td>
      </tr>
      <tr>
        <td>11.5</td>
        <td>65.9</td>
      </tr>
      <tr>
        <td>12</td>
        <td>67.2</td>
      </tr>
      <tr>
        <td>12.5</td>
        <td>68.5</td>
      </tr>
      <tr>
        <td>13</td>
        <td>69.7</td>
      </tr>
    </tbody>
  </table>
</div>


      
    </div>
  )
}

export default RGuide
