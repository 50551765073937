

import React, {Fragment, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './Sidebar';
import {useAlert} from "react-alert";
import {deleteCoupon, listCoupons} from "../../actions/couponAction";
import {clearErrors} from "../../actions/productAction";
import {COUPON_DELETE_SUCCESS} from "../../constants/couponConstants";
import {Link} from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import {Button} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import MetaData from "../layout/MetaData";
import SideBar from "./Sidebar";
import {DataGrid} from "@material-ui/data-grid";
const CouponList = ({ history }) => {
    const dispatch = useDispatch();

    const alert = useAlert();

    const { error, coupons } = useSelector((state) => state.couponList);

    const { error: deleteError, isDeleted } = useSelector(
        (state) => state.couponList
    );

    const deleteCouponHandler = (id) => {
        dispatch(deleteCoupon(id));
    };

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }

        if (deleteError) {
            alert.error(deleteError);
            dispatch(clearErrors());
        }

        if (isDeleted) {
            alert.success("Coupon Deleted Successfully");
            history.push("/admin/dashboard");
            dispatch({ type:  COUPON_DELETE_SUCCESS});
        }

        dispatch(listCoupons());
    }, [dispatch, alert, error, deleteError, history, isDeleted]);

    const columns = [
        { field: "id", headerName: "Coupon ID", minWidth: 200, flex: 0.5 },

        {
            field: "name",
            headerName: "Name",
            minWidth: 150,
            flex: 0.5,
        },
        {
            field: "code",
            headerName: "Code",
            minWidth: 100,
            flex: 0.5,
        },
        {
            field: "isActive",
            headerName: "Active",
            minWidth: 20,
            flex: 0.3,
        },
        {
            field: "validFrom",
            headerName: "Valid from",
            minWidth: 100,
            flex: 0.5,
        },
        {
            field: "validUntil",
            headerName: "Valid until",
            minWidth: 100,
            flex: 0.5,
        },

        {
            field: "actions",
            flex: 0.3,
            headerName: "Actions",
            minWidth: 150,
            type: "number",
            sortable: false,
            renderCell: (params) => {
                return (
                    <Fragment>
                        <Link to={`/admin/coupon/${params.getValue(params.id, "id")}`}>
                            <EditIcon />
                        </Link>

                        <Button
                            onClick={() =>
                                deleteCouponHandler(params.getValue(params.id, "id"))
                            }
                        >
                            <DeleteIcon />
                        </Button>
                    </Fragment>
                );
            },
        },
    ];

    const rows = [];

    coupons &&
    coupons.forEach((item) => {
        rows.push({
            id: item._id,
            code: item.code,
            name: item.name,
            isActive: item.isActive,
            validUntil: item.validUntil,
            validFrom: item.validFrom

        });
    });

    return (
        <Fragment>
            <MetaData title={`ALL COUPONS - Admin`} />

            <div className="dashboard">
                <SideBar />
                <div className="couponListContainer">
                    <h1 id="couponListHeading">ALL COUPONS</h1>

                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        disableSelectionOnClick
                        className="couponListTable"
                        autoHeight
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default CouponList;
