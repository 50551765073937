import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, getProduct } from "../../actions/productAction";
import Loader from "../layout/Loader/Loader";
import ProductCard from "../Home/ProductCard";
import Pagination from "react-js-pagination";
import Slider from "@material-ui/core/Slider";
import { useAlert } from "react-alert";
import Typography from "@material-ui/core/Typography";
import MetaData from "../layout/MetaData";
import Button from "@material-ui/core/Button";
import "../Product/Products.css";
import { GoChevronRight } from "react-icons/go";
import { GoChevronLeft } from "react-icons/go";

const subcategories = [
  "Mang Tikka","Waist Chains","Toe Rings","Armlets"
  // Add more subcategories if needed
];

const Accessories = ({ match }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [currentPage, setCurrentPage] = useState(1);
  const [price, setPrice] = useState([0, 250000]);
  const [subcategory, setSubcategory] = useState("");
  const [ratings, setRatings] = useState(0);

  const {
    products,
    loading,
    error,
    productsCount,
    resultPerPage,
    filteredProductsCount,
  } = useSelector((state) => state.products);

  const keyword = match.params.keyword;

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct(keyword, currentPage, price, "Accessories", subcategory, ratings));
  }, [dispatch, keyword, currentPage, price, subcategory, ratings, alert, error]);

  const setCurrentPageNo = (e) => {
    setCurrentPage(e);
  };

  const priceHandler = (event, newPrice) => {
    setPrice(newPrice);
  };

  const handleSubcategoryChange = (event) => {
    setSubcategory(event.target.value);
  };

  const handleResetFilters = () => {
    setCurrentPage(1);
    setPrice([0, 25000]);
    setSubcategory("");
    setRatings(0);
    dispatch(getProduct(keyword, 1, [0, 25000], "Accessories", "", 0));
  };

   const priceRanges = [
    { label: "All Prices", value: [0, 250000] },
    { label: "₹0 - ₹5,000", value: [0, 5000] },
    { label: "₹5,000 - ₹10,000", value: [5000, 10000] },
    { label: "₹10,000 - ₹50,000", value: [10000, 50000] },
    { label: "₹50,000 - ₹1,00,000", value: [50000, 100000] },
    { label: "Above ₹1,00,000", value: [100000, 250000] },
  ];
  
  const ratingOptions = [
    { value: 0, label: "All Ratings" },
    { value: 1, label: "1 Star & Above" },
    { value: 2, label: "2 Stars & Above" },
    { value: 3, label: "3 Stars & Above" },
    { value: 4, label: "4 Stars & Above" },
    { value: 5, label: "5 Stars" },
  ];
    
  const ratingsHandler = (event) => {
    const selectedRating = parseInt(event.target.value);
    setRatings(selectedRating);
  };

  

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Accessories -- ECOMMERCE" />
          <div className="filterBox">
           
            {/* Price Dropdown */}
            <select value={price} onChange={priceHandler} className="priceSelect">
              {priceRanges.map((range) => (
                <option key={range.label} value={range.label}>
                  {range.label}
                </option>
              ))}
            </select>


            {/* Subcategory Dropdown */}
            <select value={subcategory} onChange={handleSubcategoryChange} className="subcategorySelect">
              <option value="">All Subcategories</option>
              {subcategories.map((subcat) => (
                <option key={subcat} value={subcat}>
                  {subcat}
                </option>
              ))}
            </select>

            
            {/* Ratings Dropdown */}
            <select value={ratings} onChange={ratingsHandler} className="ratingsSelect">
              {ratingOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
           {/* Reset Filters Button */}
           <Button variant="contained" color="secondary" onClick={handleResetFilters}>
              Reset Filters
            </Button>
          </div>
          
          <div className="products">
            <h2 className="productsHeading">Accessories</h2>
            <div className="container">
              {products && products.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))}
            </div>
          </div>
          {resultPerPage < filteredProductsCount && (
            <div className="paginationBox">
              <Pagination 
                activePage={currentPage}
                itemsCountPerPage={resultPerPage}
                totalItemsCount={productsCount}
                onChange={setCurrentPageNo}
                nextPageText={<GoChevronRight />}
                prevPageText={<GoChevronLeft />}
                itemClass="page-item"
                linkClass="page-link"
                activeClass="pageItemActive"
                activeLinkClass="pageLinkActive"
              />
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Accessories;
