import React from 'react'
import './ShippingPolicy.css'

const ShippingPolicy = () => {
  return (
    <div className='shipolicy'>
        <h1>  Shipping policies: </h1>
       
<li>We offer free shipping within India on all online orders. We strive to ensure the safe 
    and timely delivery of all products. We deliver to almost all pin codes across India.</li>
<li>Most of the products displayed on our website are readily available and can be dispatched 
    within 24 hours of purchase. The delivery will mostly reach your doorstep 
    within 3-5 working days after dispatch.</li>

   <h1>Delivery Process:</h1>
<li>The customer must give the correct details of the Recipient Name (as stated in the photo identification) 
  with complete Address, nearby landmark, pin code, and contact number 
  for hassle-free delivery.</li>
<li>At the time of delivery, the recipient will have to provide any of the
   below-mentioned identity proofs to collect the product, if requested by the courier agent.
    Customers are expected to cooperate with them to ensure safe delivery:</li>
<ol>
*Passport<br></br>
*PAN-Card<br></br>
*Driver-License<br></br>
*Voters-ID<br></br>
* Aadhaar Card<br></br>
</ol>

<li>If the package is found to be tampered with, the customers must reject it and report
   it to us through  contact@infispec.in</li>
<li>When the recipient is not available at the address during the first delivery attempt, 
  the product will be returned to us.</li>

 



      
    </div>
  )
}

export default ShippingPolicy
