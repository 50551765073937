import React from "react"
import contact from "../Contact/Contact.js"
import "./Footer.css"
import { Link } from "react-router-dom"
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa"

const Footer = () => {
  return (
    <footer id="footer">
      <div className="conatiner">
        <div className="title">
          <h1>Borcelle jewellery</h1>
        </div>
        <div className="center">
          <div className="col col-1">
            <h2>Contact</h2>
            <ul>
             <li className="org-web"> <a href="https://www.infispec.com/" target="_blank" rel="noopener noreferrer">www.borcelle.com </a></li>
              <li>contact@borcelle.in</li>
              <li>+91 9988776655 </li>
              <li>
                <a href="contact" className="query">
                  Query
                </a>
              </li>
              <li>
                {" "}
                <a
                  href="https://www.instagram.com"
                  target="_blank" rel="noopener noreferrer"
                  class="fa fa-instagram"
                  aria-hidden="true"
                >
                  {" "}
                  <FaInstagram />
                </a>
                <a
                  href="https://www.youtube.com"
                  target="_blank" rel="noopener noreferrer"
                  className="fa fa-youtube "
                  aria-label="YouTube"
                >
                  <FaYoutube />
                </a>
                <a
                  href="https://www.linkedin.com"
                  target="_blank" rel="noopener noreferrer"
                  class="fa fa-linkedin"
                  aria-hidden="true"
                >
                  {" "}
                  <FaLinkedin />
                </a>
                <a href="https://www.facebook.com/"
                target="_blank" rel="noopener noreferrer"
                className="fa fa-facebook ">
                  <FaFacebook />
                 
                </a>
              </li>
            </ul>
          </div>
          <div className="col col-2">
            <h2>More Information</h2>
            <ul>
             <center><li><a href ="/RefundPolicy">Refund & Cancellation policy  </a></li>
              <li><a href="/ShippingPolicy">Shipping policy & Delivery </a></li>
              <li><a href ="/FAQ">FAQ </a></li></center> 
            </ul>
          </div>
          <div className="col col-3">
            <h2>Jewellery Guide</h2>
            <ul>
           <center>   
            <li><a href="/JGuide">Jewellery Care </a></li>
              <li><a href="/RGuide">Ring Size Guide </a></li>
              <li><a href="/BGuide">Bangle Size Guide </a></li>
              <li><a href="/OfficeSpecial">Office special </a> </li>
              <li><a href="/Bridal">Bridal special</a></li></center>
            </ul>
          </div>
          <div className="col col-4">
            <h2>Know Us</h2>
            <ul className="knowus">
             <center><li><a href="/OurStory">Our Story </a></li>
              <li><a href="/Blogs">Blogs </a></li>
              <li><a href="/GuidanceArticle">Guidance Articles</a></li>
              <li><a href="/NewsLetter">News letter </a></li></center> 
            </ul>
          </div>
        </div>
        <div className="bottom">
          <p>
            © 2024 - 2025 Infispec Innovations Pvt. Ltd. All Rights Reserved.
          </p>
          <Link to="/terms">Terms of Use</Link> |{" "}
          <Link to="/privacypolicy">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer

{
  /* <div className="leftFooter">
        <h4>DOWNLOAD OUR APP</h4>
        <p>Download App for Android and IOS mobile phone</p>
        <img src={playStore} alt="playstore" />
        <img src={appStore} alt="Appstore" />
      </div>

      <div className="midFooter">
        <h1>INFISPEC ECOMMERCE.</h1>
        <p>High Quality is our first priority</p>

        <p>Copyrights 2021 &copy; Infispec</p>
      </div>

      <div className="rightFooter">
        <h4>Follow Us</h4>
        <a href="http://instagram.com/">Instagram</a>
        <a href="http://youtube.com/">Youtube</a>
        <a href="http://instagram.com/">Facebook</a>
      </div> */
}
