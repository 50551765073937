import React from 'react'
import './BGuide.css';

const BGuide = () => {
  return (
    <div className='bguide'>
     <h2>  Bangle Size Guide </h2>
 Measuring bangle size is important because it ensures a comfortable fit, enhancing wearability
and preventing discomfort or loss. A well-sized bangle looks aesthetically pleasing,allows for
easy wear, and accommodates personal style preferences. Accurate sizing is especially crucial
for valuable pieces, protecting your investment. Additionally, knowing the right size is helpful
when gifting, ensuring the recipient can enjoy their bangle immediately. Refer below steps for
accurately measuring your bangle size<br></br><br></br>
 <b>1.Gather Materials:</b><br></br>
-A flexible measuring tape or a piece of string/ribbon<br></br>
-A ruler (if using string/ribbon)<br></br><br></br>
<b>2.Measure Your Hand:</b><br></br>
Open Your Hand: Extend your fingers and relax your hand.<br></br>
Wrap the Measuring Tape: Measure around the widest part of your hand (usually at the
knuckles).<br></br>
Record the Measurement: Note the measurement in centimeters or millimeters.<br></br><br></br>
<b>3.Consult a Size Chart:</b><br></br>
Use the measurement to find your bangle size using the following guide:


<div class="bangle-size-guide">
    <table>
        <thead>
            <tr>
                <th>Bangle Size (Indian)</th>
                <th>Circumference (mm)</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>2 (Small)</td>
                <td>50.8</td>
            </tr>
            <tr>
                <td>2.4 (Medium)</td>
                <td>60.0</td>
            </tr>
            <tr>
                <td>2.6 (Large)</td>
                <td>66.0</td>
            </tr>
            <tr>
                <td>2.8</td>
                <td>71.0</td>
            </tr>
            <tr>
                <td>3.0</td>
                <td>76.0</td>
            </tr>
            <tr>
                <td>3.2</td>
                <td>82.0</td>
            </tr>
            <tr>
                <td>3.4</td>
                <td>87.0</td>
            </tr>
            <tr>
                <td>3.6</td>
                <td>92.0</td>
            </tr>
        </tbody>
    </table>
</div>

      
    </div>
  )
}

export default BGuide
