import React from 'react'
import './RefundPolicy.css'

const RefundPolicy = () => {
  return (
    <div className='refund'>

    <h1>Refund policy:</h1>
<li>You have 10 days to make sure your purchase meets your expectations with our 
We are happy to refund 100% of the product cost without charging you any shipping cost 
  if you decide to return the product.</li>
<li>
This refund policy is applicable only if the products are shipped back to us within 10 days.</li>
<li>This facility can be available by returning the product through our official website within 10 days from delivery.</li>
<li>Return the items in their original packaging with their original bill and product certificate (if any).</li>
<li>Products such as gold coins, gold bars, silver coins, silver bars, gift vouchers, gift cards, digital gift cards, customized ornaments, gold nose pins, and loose gemstones are non-returnable.</li>
<li>We will deduct the original discount amount or gift value from your purchase if you received a discount or gift during your purchase. You can also choose to return the gift to us while returning.</li>

<div className='cancel'>
<h1>Cancellation Policy:</h1>
<li>Before initiating the cancellation request, we are happy to answer any product-related questions you may have.</li>
<li>You can place the cancellation request by going to the official website and selecting the orders you wish to cancel.</li>
<li>Also, you can cancel the order by sending your consent from your registered email to contact contact@infispec.in</li>
<li>Cancellation is not possible post-dispatch of the product.</li>
<li>Once the cancellation process is complete, you will receive a confirmation mail to the registered 
   email ID and the amount will be refunded back through the same mode of payment 
   within 7-10 working days.</li>
<li>If you have any queries, we shall be glad to answer them before initiating the cancellation request.</li>
<li>This cancellation policy is not applicable for Customized ornaments/Gold coins/Gold bars/Silver Coins/Silver Bars,
     Gift Vouchers, Gift Cards, and digital gift vouchers.</li>


</div>
      
    </div>
  )
}

export default RefundPolicy
