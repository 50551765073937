import React, { Fragment,useState, useEffect } from "react";
import "./Home.css";
import ProductCard from "./ProductCard.js";
import MetaData from "../layout/MetaData";
import { Link } from "react-router-dom"; // Import Link for navigation
import { clearErrors, getProduct } from "../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";
import image1 from "../../images/image1.png";
import Img1 from '../../images/ProImage1.png';
import Img2 from '../../images/ProImage2.png';
import Img3 from '../../images/ProImage3.png';
import Img4 from '../../images/ProImage4.png';
import Img5 from '../../images/ProImage5.png';
import banner1 from '../../images/banner1.jpg';
import banner2 from '../../images/banner2.jpg';
import banner5 from '../../images/banner5.jpg';
import banner7 from '../../images/banner7.jpg';
import bannerImage from '../../images/bannerImage.jpeg'
import ribbonImage from '../../images/ribbon_image.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const ProductsData = [
  {
    id: 1,
    img: Img1,
    title: "Ear Rings",
    model: "white",
    aosDelay: "0",
    link: "/ear-rings"
  },
  {
    id: 2,
    img: Img2,
    title: "Necklaces",
    model: "white",
    aosDelay: "200",
    link: "/necklaces"
  },
  {
    id: 3,
    img: Img3,
    title: "Mangalsutra",
    model: "white",
    aosDelay: "400",
    link: "/mangalsutra"
  },
  {
    id: 4,
    img: Img4,
    title: "Nose Pins & Rings",
    model: "white",
    aosDelay: "600",
    link: "/nose-rings"
  },
  {
    id: 5,
    img: Img5,
    title: "HandRings",
    model: "white",
    aosDelay: "800",
    link: "/rings"
  }
];

const bannerData = [
  {img:banner1, link: "/necklaces"},
  {img:banner2, link:"/necklaces"},
  {img:banner5,link:"/bracelets"},
  {img:banner7, link:"/nose-rings"},
];

const NextArrow = ({ onClick }) => {
  return (
    <button className="slick-next" onClick={onClick}>
       &rarr;
    </button>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <button className="slick-prev" onClick={onClick}>
      &larr;
    </button>
  );
};

const Home = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, products } = useSelector((state) => state.products);

 
  const [showAllFeatured, setShowAllFeatured] = useState(false);
  const [initialFeaturedCount] = useState(3);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct());
  }, [dispatch, error, alert]);

  const topRatedProducts = products?.filter(product => product.ratings >= 4);

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    cssEase: "ease-in-out",
    pauseOnHover: true,
    pauseOnFocus: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const bannerSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    cssEase: "ease-in-out",
    pauseOnHover: true,
    pauseOnFocus: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  
  const handleExploreClick = () => {
    setShowAllFeatured(true);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="INFISPEC ECOMMERCE" />

          <div className="categories-row">
            <ul className="categories-list">
              <li>
                <Link to="/ear-rings">Ear Rings</Link>
              </li>
              <li>
                <Link to="/necklaces">Necklaces</Link>
              </li>
              <li>
                <Link to="/bracelets">Bracelets</Link>
              </li>
              <li>
                <Link to="/bangles">Bangles</Link>
              </li>
              <li>
                <Link to="/mangalsutra">Mangalsutra</Link>
              </li>
              <li>
                <Link to="/nose-rings">Nose Pins & Rings</Link>
              </li>
              <li>
                <Link to="/rings">Hand Rings</Link>
              </li>
              <li>
                <Link to="/accessories">Accessories</Link>
              </li>
            </ul>
          </div>

        <div className="banner">
          <div className="banner-slider"> 
          <Slider {...bannerSettings}>
              {bannerData.map((banner, index) => (
                <div key={index}>
                  <Link to={banner.link}>
                    <img
                      src={banner.img}
                      alt={`Banner ${index + 1}`}
                      className="banner-image"
                    />
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
          

          <div id="featuredProducts"  className="featuredProducts">
            <h1 className="homeHeading">Featured Products</h1>
            <div className="container1">
            <div className="container">
               {products && products.length > 0 ? (
                  products
                  .slice(0, showAllFeatured ? products.length : initialFeaturedCount).map((product) => (
                    <div key={product.id} >
                      <ProductCard product={product} />
                    </div>
                  ))
                ) : (
                  <p>No products found</p>
                )}
              </div>
              {!showAllFeatured && (
                <div className="explore-btn-container">
                  <button className="explore-btn" onClick={handleExploreClick}>
                    Explore More
                  </button>
                </div>
              )}           
                
             
            </div>
          </div>

          <div className="ribboncontainer">
            <div className="ribbon-container1">
              <h3 className="ribbon-title">Our Journey-Crafting Timeless Elegance </h3>
              <p className="ribbon-description">
              At Borcelle Jewellery, <br></br>we craft more than just jewellery—we create connections.
               Each piece reflects timeless elegance, inspired by tradition and designed 
               with love.<br></br> Our passion lies in making your stories shine, turning moments
                into lasting memories with jewellery as unique as you. 
              </p>
            </div>
            <div className="ribbon-container2">
              <div>
                <img src={ribbonImage} alt="ribbon" />
              </div>
            </div>
          </div>

          <div className="Categories-container">
            <div className="Categories">
              <div className="header-section">
                <h1 className="title">Categories</h1>
              </div>
              <div className="body-section">
                <div className="product-grid">
                  {ProductsData.map((data) => (
                    <div
                      key={data.id}
                      className="product-card"
                      data-aos="fade-up"
                      data-aos-delay={data.aosDelay}
                    >
                      <a href={data.link}>
                        <img src={data.img} alt={data.title} className="product-image" />
                      </a>
                      <div>
                        <h3 className="product-title">{data.title}</h3>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div id="topRatedProducts" className="topRatedProducts">
            <h1 className="homeHeading">Top Rated Products</h1>
            <div className="container1">
              <Slider {...settings}>
                {topRatedProducts && topRatedProducts.length > 0 ? (
                  topRatedProducts.map((product) => (
                    <div key={product.id} className="slider-slide">
                      <ProductCard product={product} />
                    </div>
                  ))
                ) : (
                  <p>No top-rated products found</p>
                )}
              </Slider>
            </div>
          </div>

        </Fragment>
      )}
    </Fragment>
  );
};

export default Home;
