import React from 'react'
import './NewsLetter.css';
import b1 from '../About/Images/b1.jpg';
import pearl from '../About/Images/pearl.jpg';
import dneck from '../About/Images/dneck.jpg';


const NewsLetter = () => {
  return (
    <div className='news'>
       
      <h3> Brocelle Jewelry September Newsletter: Elevate Your 
      Style with Timeless Elegance ✨</h3><br></br>
 Dear Customer,<br></br><br></br>

Welcome to the world of Brocelle Jewelry, where craftsmanship meets elegance.
 As we step into a new season, now is the perfect time to refresh your collection with pieces 
 that radiate beauty and sophistication.<br></br><hr></hr><br></br>

 <b>Discover Our Latest Arrivals: New Fall Collection 🍂</b><br></br>
Our Fall Collection has just arrived, bringing you the essence of autumn with exquisite designs. 
Each piece is crafted with precision to complement the season’s warm tones and textures.<br></br><br></br>

<b>Explore the collection:</b><br></br>
<div className='set-container'>
  <img className="news-bangle"src={b1} alt={b1}/>
  <img className="pearl" src={pearl} alt={pearl}/>
  <img className="dneck" src={dneck} alt={dneck}/>
</div>

<b> Brocelle Gold Bangles – </b>A perfect blend of tradition and modernity.<br></br>
 <b>Pearl-Embellished Mangalsutra – </b> Timeless charm with a contemporary twist.<br></br>
<b> Statement Necklaces – </b> Bold designs that stand out.<br></br>
 Shop Now and be the first to wear these stunning new designs!<br></br><hr></hr><br></br>

  <b>Limited Time Offer: 15% OFF New Arrivals! 🎉</b><br></br>
To celebrate the launch of our new collection, enjoy 15% OFF with the code FALLJEWELS15.
 Offer valid until September 30th.<br></br>

Don’t miss out on this chance to add Brocelle’s unique jewelry to your 
wardrobe!<br></br><br></br><hr></hr><br></br>


<b>Customer Favorites: Bestselling Jewelry You’ll Love </b><br></br>
Wondering what pieces are trending? These customer favorites are flying 
off the shelves:<br></br><br></br>

<b>Dazzling Gold Earrings – </b> A must-have for any occasion.<br></br>
<b>Elegant Diamond Rings – </b>The perfect way to express your unique style.<br></br>
<b>Layered Necklace Sets – </b>Add dimension and glamour to your outfits.<br></br>
Check out these favorites and find the perfect piece
 to add to your collection.<br></br><br></br><hr></hr><br></br>


<b> Style Tips: How to Make Your Jewelry Stand Out</b><br></br>
  Here are some tips from the experts at Brocelle to make sure you shine all season long:<br></br><br></br>

<b>Mix and Match: </b> Pair gold and silver for a versatile, trendy look.<br></br>
<b>Layer It Up: </b>Layer necklaces or bangles for added sophistication.<br></br>
<b>Go Bold: </b>Let statement pieces take center stage
 with simpler outfits.<br></br><br></br><hr></hr><br></br>


<b> Exclusive: Free Shipping on All Orders Above $100 🚚</b><br></br>
  For a limited time, enjoy FREE SHIPPING on all orders over $100. 
  Shop with confidence and get your Brocelle Jewelry delivered 
  right to your door!<br></br><br></br><hr></hr><br></br>


 <b> Follow Us for More Inspiration</b><br></br>
For the latest updates, styling ideas, and special offers, follow Brocelle Jewelry on 
social media:<br></br>

Instagram: @BrocelleJewelry<br></br>
Facebook: Brocelle Jewelry<br></br>
Pinterest: Brocelle Jewelry<br></br>
Thank you for being part of the Brocelle Jewelry family. We look forward to helping you 
find your perfect piece!<br></br><br></br>

Warm regards,<br></br>
Founder, Brocelle Jewellery<br></br>



    </div>
  )
} 

export default NewsLetter
