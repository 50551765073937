import React, { useEffect } from "react";
import Sidebar from "./Sidebar.js";
import "./dashboard.css";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Doughnut, Line, Pie, Bar } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import { getAdminProduct } from "../../actions/productAction";
import { getAllOrders } from "../../actions/orderAction.js";
import { getAllUsers } from "../../actions/userAction.js";
import MetaData from "../layout/MetaData";
// import Chart from "./Chart.js";

const Dashboard = () => {
  const dispatch = useDispatch();

  const { products } = useSelector((state) => state.products);
  const { orders } = useSelector((state) => state.allOrders);
  const { users } = useSelector((state) => state.allUsers);

  let outOfStock = 0;
  products &&
    products.forEach((item) => {
      if (item.Stock === 0) {
        outOfStock += 1;
      }
    });

  useEffect(() => {
    dispatch(getAdminProduct());
    dispatch(getAllOrders());
    dispatch(getAllUsers());
  }, [dispatch]);

  let totalAmount = 0;
  orders &&
    orders.forEach((item) => {
      totalAmount += item.totalPrice;
    });

    
  const lineState = {
    labels: ["Initial Amount", "Amount Earned"],
    datasets: [
      {
        label: "TOTAL AMOUNT",
        backgroundColor: ["tomato"],
        hoverBackgroundColor: ["rgb(197, 72, 49)"],
        data: [0, totalAmount],
      },
    ],
  };

  const doughnutState = {
    labels: ["Out of Stock", "InStock"],
    datasets: [
      {
        backgroundColor: ["#00A6B4", "#6800B4"],
        hoverBackgroundColor: ["#4B5000", "#35014F"],
        data: [outOfStock, products.length - outOfStock],
      },
    ],
  };

  let categoryData = {};
  products &&
    products.forEach((item) => {
      if (categoryData[item.category]) {
        categoryData[item.category] += 1;
      } else {
        categoryData[item.category] = 1;
      }
    });

  const pieState = {
    labels: Object.keys(categoryData),
    datasets: [
      {
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#FF6384",
          "#36A2EB",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#FF6384",
          "#36A2EB",
        ],
        data: Object.values(categoryData),
      },
    ],
  };

  const monthlyEarnings = Array(12).fill(0);

  orders &&
    orders.forEach((order) => {
      const month = new Date(order.createdAt).getMonth();
      monthlyEarnings[month] += order.totalPrice;
    });

  const barState = {
    labels: [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ],
    datasets: [
      {
        label: "Monthly Earnings",
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        hoverBackgroundColor: "rgba(75, 192, 192, 1)",
        data: monthlyEarnings,
      },
    ],
  };

  const yearlyEarnings = {};

  orders &&
    orders.forEach((order) => {
      const year = new Date(order.createdAt).getFullYear();
      if (yearlyEarnings[year]) {
        yearlyEarnings[year] += order.totalPrice;
      } else {
        yearlyEarnings[year] = order.totalPrice;
      }
    });

  const barYearlyState = {
    labels: Object.keys(yearlyEarnings),
    datasets: [
      {
        label: "Yearly Earnings",
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        hoverBackgroundColor: "rgba(153, 102, 255, 1)",
        data: Object.values(yearlyEarnings),
      },
    ],
  };

  return (
    <div className="dashboard">
      <MetaData title="Dashboard - Admin Panel" />
      <Sidebar />

      <div className="dashboardContainer">
        <Typography component="h1">Dashboard</Typography>

        <div className="dashboardSummary">
          <div>
            <p>
              Total Amount <br/><br/><br/>
              <span className="totalAmountValue">₹{totalAmount}</span>
            </p>
          </div>
          <div className="dashboardSummaryBox2">
            <Link to="/admin/products">
              <p>Product</p>
              <p>{products && products.length}</p>
            </Link>
            <Link to="/admin/orders">
              <p>Orders</p>
              <p>{orders && orders.length}</p>
            </Link>
            <Link to="/admin/users">
              <p>Users</p>
              <p>{users && users.length}</p>
            </Link>
          </div>
        </div>

        {/* <div className="lineChart">
          <Line data={lineState} />
        </div> */}

        <div className="charts1">
          <div className="doughnutChart">
            <Doughnut data={doughnutState} />
          </div>

          <div className="pieChart">
            <Pie data={pieState} />
          </div>
        </div>

        <div className="barChart">
          <Bar data={barState} />
        </div>

        <div className="barYearlyChart">
          <Bar data={barYearlyState} />
        </div>

        {/* <div>
          <Chart />
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
