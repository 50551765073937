import React from 'react';
import "./privacy.css"

const PrivacyPolicy = () => {
  return (
    <div className="paragraph-page">
      <h1>Privacy Policy for Infispec Ecommerce</h1>
      <p>
        <ol>
          
<li>
Introduction
Welcome to Infispec Ecommerce. We respect your privacy and are committed to protecting 
your personal data. This privacy policy will inform you about how we look after your
 personal data when you visit our website and tell you about your privacy rights and how 
 the law protects you.</li>
 
<li>Data We Collect
We may collect, use, store and transfer different kinds of personal data about you, including but not limited to:
 
    Identity Data (name, username, profile pictures)
    Contact Data (billing address, delivery address, email address and telephone numbers)
    Financial Data (payment card details)
    Transaction Data (details about payments to and from you and other details 
    of products you have purchased from us) </li>
 
<li> How We Collect Your Data
We use different methods to collect data from and about you, including through:
 
    Direct interactions (you may give us your Identity, Contact and Financial Data by filling in forms or by corresponding with us)
    Automated technologies or interactions (as you interact with our website, 
    we may automatically collect Technical Data about your equipment, browsing 
    actions and patterns) </li>
 
<li>How We Use Your Data
We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:
 
    To register you as a new customer
    To process and deliver your orders
    To manage our relationship with you
    </li>
 
<li>Data Security
We have put in place appropriate security measures to prevent your personal data from 
being accidentally lost, used or accessed in an unauthorized way.
</li>
 
<li>Data Retention
We will only retain your personal data for as long as necessary to fulfill the purposes we 
collected it for, including for the purposes of satisfying any legal, accounting, or reporting 
requirements.
</li>
 
<li>Your Legal Rights
You have rights under data protection laws in relation to your personal data, 
including the right to request access, correction, erasure, restriction, transfer, 
or to withdraw consent.
</li>
 
<li>Third Parties
We may have to share your personal data with third parties, including third-party 
service providers and other entities in the group.
</li>
 
<li>Contact Us
If you have any questions about this privacy policy or our privacy practices, 
please contact us at [contact information - +91 9790333937]
</li>
    </ol>  
    </p>
    </div>
  )
};

export default PrivacyPolicy;
