import React from 'react'
import './GuidanceArticle.css';
import wedring from '../About/Images/wedring.jpg'
import bangles from '../About/Images/bangles.jpg';
import chain from '../About/Images/chain.jpg';
import earing from '../About/Images/earing.jpg';



const GuidanceArticle = () => {
  return (
    <div className='artcile'>
      <div className='article-content'>
        <h3>Guidance Article</h3>
        <div className='article-text'>       
      <h4> Buying Guides </h4> <br></br>
      <div className='image-container'>
      <img className='image' src={wedring} alt={wedring} width="500px" height="400px" />
      </div>
 <b> 1.How to Choose the Perfect Engagement Ring:</b><br></br>
 Engagement rings come in a variety of metal bands – gold, platinum, white gold,
  rose gold and more. Depending on the taste of your partner, you will need to select a
   suitable band. Every metal is beautiful, but it all comes down to preference. We suggest
    taking a look at your partner’s jewellery choice, rings explicitly, to confirm their liking.<br></br>
   Discuss the 4Cs (carat, cut, clarity, color), setting styles, and personal preferences.<br></br>
  <b>Cut: </b> Diamonds that are cut well can efficiently bounce light off from one edge to another 
       and add a superb brilliance to the stone. It all depends on the jewel cutter. 
       The cut greatly impacts the final look of the rock. There are several different cut styles
        for your perusal.<br></br>
   <b>Color: </b>Diamond colouration is graded from D (highest – colourless) to Z (light yellow). 
   Colorless diamonds are rare and expensive, but what you see in the market are mainly white 
   colour diamonds. The colour makes a difference to the overall look, so it is essential to 
   inquire about it.<br></br>

  <b>Clarity: </b>Clarity refers to the lack of imperfections in the diamond. The fewer the
   inclusions (minerals or tiny fractures in the diamond), the better. They start from IF
    (Internally flawless), to VVS (very very slightly included) to VS ( Very slightly included)
     S (Slightly included) and finally to I (Included).<br></br>

  <b>Carat: </b>Everyone must have heard of this one. Carat is the weight of the stone. 
  The heavier the stone, the larger the carat weight.<br></br><br></br>
 
  <div className='bangle-container'>
  <img className='bangle' src={bangles} alt={bangles} width="400px" height="300px"/>

 </div>
 <b> 2.A Guide to Wedding Bangles: </b><br></br> 
  Explore different band styles, metals, and matching options with wedding bands.<br></br>
 <b> Style:</b> Whether you want a matching or non-matching set, and if you want the wedding 
 band to complement your engagement ring <br></br>
  <b>Design: </b> The profile, style, width, and metal of the ring <br></br>
<b>Comfort:</b> Whether the ring is comfortable enough to wear every day <br></br>
<b>Lifestyle:</b> How the ring will fit into your lifestyle <br></br>
<b>Budget:</b> Wedding bands come in a variety of prices, so it's important to set a budget <br></br>
<b>Color: </b>Whether the color matches your complexion or hair color <br></br>
<b>Existing jewelry:</b> Whether the ring matches your existing jewelry <br></br>
<b>Durability:</b> If you're an active person, you might want a more durable material like gold <br></br><br></br>
  

  <div className='chain-container'>
    <img className='chain'src={chain} alt={chain} width="400px" height="300px"/>
  </div>
  <b>3.Choosing the Right Necklace Length:</b> <br></br>
 <b> Outfit :</b> 
  If you're wearing a high neckline, a longer necklace (24–30 in) might work well. 
  For a low-cut top or dress, a 16 in choker or 18 in necklace might be a better choice. <br></br>
  <b>Body type :</b>
  A necklace will sit higher on someone who is taller than on someone with a shorter torso. <br></br>
  <b>Style :</b>
   Your personal style preferences can also help you decide on the best length. 
   For example, some people prefer chokers, while others like layering pearls. <br></br><br></br>


<div className='earing-container'>
  <img className='earing' src={earing} alt={earing} width="350px" height="300px"/>
</div>
  <b>4.Earring Styles for Every Face Shape:</b> <br></br>
 <b>Inverted triangle shaped face:</b> 
  Choose earrings that de-emphasise your wide forehead and create the illusion of width at the jawline. Chandelier or teardrop earrings work well.
  Recommend earring styles that flatter different face shapes.<br></br>

  <b>Oval face :</b>
  You can wear almost any shape, but triangular earrings or simple studs will show off your 
  lovely cheekbones best.<br></br>

  <b>Round face : </b>
  Drop or dangle earrings will elongate your face and make it look slimmer. Avoid large circular 
  earrings, hoops and button studs which only emphasise the roundness.<br></br>

  <b>Heart shaped face :</b>
  Counterbalance your sharp chin with chandelier or teardrop earrings. Earrings that are wider 
  at the bottom than the top help fill in the lower portion of your face, so your face is more 
  balanced.<br></br>

  <b>Long and Narrow face :</b>
  Studs, clustered earrings, short dangles, hoops in a medium to large size. 
  Any type of round earrings will widen your face, and make it look fuller.<br></br>

  <b>Square face : </b>
 Go for earrings that are medium to long with rounded edges. Oval shapes soften things up, 
 and circular earrings and hoop earrings work well too.<br></br><br></br>

 <b>5.How to Clean Different Types of Jewelry:</b><br></br>
<b> Use a soft cloth:</b> A soft, lint-free cloth can help keep jewelry shiny and remove dirt
 and oil streaks. <br></br>
<b>Use a soft-bristled brush: </b>A soft-bristled brush can gently scrub jewelry and remove dirt
 and debris. A soft toothbrush is especially good for cleaning gems and stone-set jewelry. <br></br>
<b>Use a jewelry cleaner:</b> Jewelry cleaners can be effective for many types of jewelry, but 
you should avoid using them on jewelry that isn't made from 10k, 14k, 18k gold, or platinum. <br></br>
<b>Use dish soap:</b> Dish soap is a good at-home cleaning solution for fine jewelry. You can
 soak jewelry in a solution of mild dish soap and warm water for 15 minutes. <br></br>
<b>Use warm water and gentle soap:</b> You can wash jewelry with warm water and a gentle, 
colorless, and fragrance-free liquid soap. Castile soap, soap for woollens, or a gentle hand
 soap without harsh chemicals can work well.<br></br> 
<b>Avoid submerging in water: </b>Don't submerge jewelry completely in water. <br></br>
<b>Avoid abrasive products:</b> Don't use abrasive or too aggressive products on jewelry. <br></br> <br></br>

<b>6.How to Store the Jewelry Properly? </b><br></br>
 <b> Store separately :</b>
 Keep jewelry items in separate cases to prevent scratching and tangling.<br></br> 
<b>Store in a safe, dry place :</b>
Avoid storing jewelry in humid areas, which can cause oxidation and rust, especially for 
sterling silver and plated jewelry. Keep jewelry away from heat sources like radiators. <br></br>
 <b>Use a jewelry box:</b>
Jewelry boxes are a good option for storing jewelry, especially if you have a lot of small pieces.
 They often have compartments and dividers to keep things organized. <br></br>
<b>Use an earring organizer:</b>
An earring organizer can help protect earrings from damage and keep your jewelry collection clean.
 Some organizers come with dust covers. <br></br>
<b>Use a jewelry tray:</b>
A jewelry tray, also called a trinket tray, is a stylish saucer for placing jewelry on.
 They are often used for temporary storage or for storing items you use daily. <br></br>
<b>Use a jewelry armoire:</b>
A jewelry armoire is a convenient storage option with drawers, in-drawer dividers, and hooks. 
Some armoires also have mirrors. <br></br>
<b>Use silica gel or activated charcoal</b>
You can add small sachets of silica gel or activated charcoal to help store your jewelry. <br></br> <br></br>


  </div>
      </div>
      
    </div>
  )
}

export default GuidanceArticle
