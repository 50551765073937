import React from 'react'
import './JGuide.css';
import jcare from '../About/Images/jcare.jpg';
import jcare1 from '../About/Images/jcare1.jpg';

const JGuide = () => {
  return (
    <div className='jguide'>
      <h2>Jewellery care</h2>
      Jewellery care is crucial for maintaining the beauty, value, and integrity of your pieces, 
      ensuring they remain cherished for years to come. A few steps below can help you 
      with the caring of your jewellery:<br></br><br></br>

   <img src={jcare} alt={jcare} width="280px" height="280px"/>
   <img src={jcare1} alt={jcare1} width="280px" height="280px"/><br></br>
   

  <b>1.Regular Cleaning:</b>  <br></br>
   Frequency: Clean your jewelry regularly, ideally every few weeks.<br></br>
   Method: Use a soft cloth to wipe down pieces after wearing. Keep Materials in mind while
   cleaning them as given below:<br></br>
   -Gold & Silver: Clean with appropriate metal cleaners; avoid abrasive materials.<br></br>
-Gemstones: Check specific care for each type; softer stones like opals and pearls require
gentler handling.<br></br>
-Costume Jewelry: Avoid water and excessive cleaning; a damp cloth
 is usually sufficient.<br></br><br></br>

<b>2.Safe Storage:</b><br></br>
Keep Them Separated: Store each piece in its own compartment to prevent scratching and
tangling. Use:<br></br>
-Jewelry boxes with dividers<br></br>
-Soft pouches<br></br>
-Ziploc bags with air removed<br></br>
Avoid Humidity: Store in a cool, dry place to prevent tarnishing, especially for silver
 and pearls.<br></br><br></br>

 <b>3.Avoiding Damage:</b><br></br>
Limit Exposure: Remove jewelry before activities that may cause damage, like:<br></br>
-Swimming (chlorine can damage)<br></br>
-Exercising (sweat can tarnish)<br></br>
-Cleaning (chemicals can harm)<br></br>
Be Mindful of Fragrance: Apply perfume, lotions, or hairspray before putting on jewelry
to avoid residue.<br></br><br></br>


    <b>4.Avoid Direct Contact with Chemicals</b><br></br>
 Harsh chemicals from household cleaning agents, lotions, perfumes, and hair products can 
 damage the surface of your jewelry.<br></br>
  To protect your pieces:<br></br>

   -Apply any makeup, lotions, or perfumes before putting on your jewelry.<br></br>
   -Remove your jewelry when cleaning, swimming, or engaging in any activity involving chemicals 
   or abrasives.<br></br><br></br>

   <b>5. Avoid Wearing Jewelry During Physical Activities</b><br></br>
   It’s best to remove your jewelry when exercising, showering, or doing any manual labor. <br></br>
   This prevents unnecessary wear and tear, such as scratches or exposure to sweat and 
   moisture, which can dull the finish of metals and gemstones.<br></br><br></br>
   

   <b>6. Regular Maintenance and Professional Inspections</b><br></br>
Even with proper care, jewelry benefits from occasional professional cleaning and inspections. 
Check clasps, prongs, and settings for any signs of wear or damage.<br></br>
 If you notice any issues, 
take your pieces to a professional jeweler for repair.<br></br><br></br>

<b>7. Traveling with Jewelry</b><br></br>
 Use Travel Cases: When traveling, store your jewelry in a padded travel case to avoid tangling 
 or damage.<br></br>
 Keep it Simple: To minimize the risk of loss or damage while traveling, consider wearing only a
  few essential pieces and leaving more valuable items at home.<br></br><br></br>

  <b>8. Repair and Restoration</b><br></br>
Routine Maintenance: Just like any cherished item, jewelry may need repair over time. Regular
 maintenance—such as tightening loose stones, re-tipping prongs, and replacing worn clasps—can 
 significantly extend the life of your pieces.<br></br>
Professional Restoration: For antique or heirloom pieces, professional restoration services
 can bring your jewelry back to its original beauty while 
 maintaining its historical integrity.<br></br><br></br>

 <b>9. Handling Specific Jewelry Types </b><br></br>
  Gold Jewelry: Gold can be prone to scratching, especially at higher karats. Store gold
   pieces individually to avoid rubbing against harder materials. Clean with a soft cloth
    after each use to maintain shine.<br></br>
Silver Jewelry: Silver is prone to tarnishing when exposed to air for long periods. To keep it
 looking bright, store silver pieces in anti-tarnish cloths or bags. Clean tarnished silver
  using a dedicated silver polish or a baking soda and water paste.<br></br>
Platinum Jewelry: Although platinum is durable, it can still scratch over time. Clean with a 
mild soap solution and avoid abrasive cleaners.<br></br>
Gemstone Jewelry: Each gemstone requires different care:<br></br>
Diamonds: Clean with warm soapy water and a soft brush to maintain brilliance.<br></br>
Opals and Pearls: These delicate stones require gentle handling. Wipe with a soft, damp cloth and
 avoid chemical cleaners.<br></br>
Emeralds and Turquoise: Avoid ultrasonic cleaners; wipe with a soft cloth and never 
expose to high heat.<br></br><br></br>

<b>10. Protecting Against Tarnish</b><br></br>
Preventative Care: Tarnish occurs when silver or other metals react with sulfur or oxygen in 
the air. To prevent this, store your jewelry in airtight containers with anti-tarnish strips
 or bags to minimize exposure to air.<br></br>
Remove Jewelry Before Sleep: Wearing jewelry to bed can cause tangling, scratching, and 
unnecessary wear. Make it a habit to remove rings, necklaces, and bracelets before going 
to sleep to preserve their shine and structure.<br></br>


</div>
  )
}

export default JGuide
