import React from 'react'
import './aboutSection.css';
import book from '../About/Images/book.png';
import mission from '../About/Images/mission.png';
import vision from '../About/Images/vision.png';
import value from '../About/Images/value.png';
import parthenon from '../About/Images/parthenon.png';
import Ethics from '../About/Images/Ethics.png';
import custom from '../About/Images/custom.png';
import builder from '../About/Images/builder.png';
import family from '../About/Images/family.png';



const About = () => {
  return (
    <div className='about-jewellery'>
      <div className='heading-part'>
        <h3>About Borcelle jewellery</h3><br></br>
        <div className='para1'>
        Welcome to Brocelle Jewelry, where craftsmanship meets elegance. At Brocelle,
         we believe that every piece of jewelry tells a story, and our goal is to make sure
          that each of our designs resonates with beauty, grace, and individuality.<br></br><br></br>


          <b>Why Choose Brocelle?</b><br></br>
        What sets us apart is our unwavering commitment to quality and customer satisfaction. 
        Each piece of Brocelle jewelry is a reflection of our dedication to excellence, and we 
        strive to make your shopping experience as seamless and enjoyable as possible. Our designs
         range from classic to contemporary, ensuring that we have something to suit every style
          and occasion.<br></br><br></br>
          

          <img src={book} alt={book} width="20px" height="20px" className="image-gap" />
<b>Our Story</b><br />
          Brocelle was born from a passion for fine jewelry and the desire to create pieces 
          that speak to the modern individual. What started as a small, family-run business has
           now grown into a thriving jewelry brand, serving customers worldwide. Yet, our values 
           remain the same—creating timeless pieces that celebrate life’s special moments.<br></br><br></br>


           <img src={mission} alt={mission} width="20px" height="20px" className="image-gap" />
      <b>Our Mission </b><br></br>
      Our mission at Brocelle is simple: to provide luxurious, high-quality jewelry that enhances
       the beauty of those who wear it. We handpick the finest materials, from radiant diamonds
        to precious metals, ensuring that each piece is crafted with precision and care. Our 
        collections are designed to make you feel confident and beautiful, whether you're shopping 
        for an engagement ring, a gift, or a personal treat. <br></br><br></br>


        <img src={vision} alt={vision} width="20px" height="20px" className="image-gap" />
       <b> Our Vision</b><br></br>
      At Brocelle Jewelry, our vision is to become a globally recognized brand synonymous with elegance,
     quality, and innovation in the jewelry industry. We aim to create timeless pieces that inspire 
       confidence and bring joy to those who wear them.<br></br>

      Our goal is to continue pushing the boundaries of design and craftsmanship, offering jewelry that
     not only complements but elevates your personal style. We envision a future where Brocelle is the
     go-to brand for individuals seeking jewelry that reflects their unique stories and values.<br></br>

      Additionally, we are committed to sustainability and ethical practices, aspiring to lead the way 
     in creating a more responsible and transparent jewelry industry. We believe that luxury should not
    come at the expense of our planet or its people, and we continuously strive to minimize our 
     environmental footprint while ensuring fair practices throughout our supply chain.

   Through innovation, creativity, and a dedication to excellence, we aim to redefine the jewelry
 experience—one piece at a time.<br></br><br></br>


 <img src={value} alt={value} width="30px" height="30px" className="image-gap" />
 <b>Our Values</b><br></br>
     At the heart of Brocelle Jewelry is a commitment to our core values:<br></br>

   <b> Integrity:</b> We believe in being transparent and honest with our customers, ensuring you
     know the origin and quality of every piece you purchase.<br></br>
   <b>Quality: </b>We never compromise on quality. From ethically sourced materials to rigorous 
   quality checks, each piece of Brocelle jewelry stands the test of time.<br></br>
   <b>Creativity: </b>We value creativity and innovation, constantly seeking new ways to 
   blend traditional craftsmanship with contemporary design trends.<br></br><br></br>


   <img src={parthenon} alt={parthenon} width="20px" height="20px" className="image-gap" />
  <b> Our Heritage</b><br></br>
    Brocelle Jewelry is more than just a brand; it is a legacy of fine craftsmanship and
     dedication to timeless beauty. Our founders come from generations of artisans who have 
     passed down the art of jewelry making. This heritage continues to inspire us as we blend 
     traditional techniques with modern designs to create pieces that are both classic and
      contemporary.<br></br>
 Every piece of jewelry is crafted with attention to detail, ensuring it becomes a cherished 
 part of your personal story. From intricate patterns to delicate gemstone settings, our heritage
  is reflected in every design we bring to life.<br></br><br></br>


  
  <img src={Ethics} alt={Ethics} width="30px" height="30px" className="image-gap" />
         <b> Sustainability and Ethics</b><br></br>
     At Brocelle, we are dedicated to sustainable practices. We ensure that all of our 
     gemstones and metals are ethically sourced, and we are constantly striving to reduce
      our environmental impact. When you wear a piece of Brocelle jewelry, you can feel good 
      knowing that it was made with care for both people and the planet.<br></br><br></br>


      <img src={custom} alt={custom} width="30px" height="30px" className="image-gap" />
      <b>Customization & Personalization</b><br></br>
    We understand that jewelry is deeply personal, and at Brocelle, we offer customization 
    services to make each piece uniquely yours. Whether you're looking to design a custom 
    engagement ring, add a personal engraving, or select the perfect gemstone, our team is 
    here to help bring your vision to life.<br></br>

    Our personalized services allow you to create meaningful pieces that reflect your personality, 
    style, and special moments. It’s more than just jewelry—it’s a memory in the making.<br></br><br></br>


    <img src={builder} alt={builder} width="30px" height="30px" className="image-gap" />
<b>   Craftsmanship & Design </b>  <br></br>
   At Brocelle, we pride ourselves on our exceptional craftsmanship. Each piece is handcrafted 
   by skilled artisans who understand the nuances of jewelry making. From the selection of 
   precious metals and gemstones to the final polish, we ensure that every detail is perfected.
  Our designs are inspired by the beauty of the natural world, cultural influences, and the
   elegance of modern simplicity. Whether you’re drawn to intricate designs or minimalist pieces,
    you’ll find that Brocelle jewelry speaks to a wide range of styles while maintaining a 
    timeless appeal.<br></br> <br></br>


    <img src={family} alt={family} width="20px" height="20px" className="image-gap" />
      <b>Join the Brocelle Family</b><br></br>
    Whether you're celebrating a special occasion or simply looking to treat yourself, Brocelle
     Jewelry is here to make those moments shine. We invite you to explore our collections and
      become part of the Brocelle family. Thank you for letting us be a part of your journey. <br></br> 
        </div>
      </div>
      
    </div>
  )
}

export default About

 