import React from "react";
import { Link } from "react-router-dom";
import { Rating } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { FaHeart } from "react-icons/fa"; // Import heart icon
import './ProductCard.css';
import { addToWishlist } from "../../actions/wishlistAction";

const ProductCard = ({ product }) => {
  const dispatch = useDispatch();

  const options = {
    value: product.ratings,
    readOnly: true,
    precision: 0.5,
  };

  const addToWishlistHandler = () => {
    dispatch(addToWishlist(product._id));
    window.location.reload();
    window.prompt("sometext","defaultText");
  };

  const discountPercentage = product.discount || 0;
  const netPrice = product.price - (product.price * discountPercentage) / 100;

  return (
    <div className="productCard">
      <Link to={`/product/${product._id}`} data-content={product.name}>
        <div className="imgBox">
          <img className="productImage" src={product.images[0].url} alt={product.name} />
        </div>
        <div className="contentBox">
          <h4>{product.name}</h4>
          <div>
            <Rating {...options} />
            <span className="productCardSpan">{`(${product.numOfReviews} )`}</span>
          </div>
          <div className="price">
            {discountPercentage > 0 && (
              <span className="strikethrough">{`₹${product.price}`}</span>
            )}
            <span className="netPrice">{` ₹${netPrice}`}</span>
          </div>
          <div className="buynow">
          <Link className="buy" to={`/product/${product._id}`}>Buy Now</Link>
          <button className="wishlistButton buy" onClick={addToWishlistHandler} title="Add to Wishlist">
            <FaHeart /><p></p>
          </button>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
