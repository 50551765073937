import React from 'react'
import './FAQ.css'

const FAQ = () => {
  return (
    <div className='questions'>
        <h2>FAQ</h2>
<ol>
      <li><b>How can I find a particular product that I am looking for?</b></li>
You can directly search for the product on the website by using the search field
 provided at the top of every page. However, please note that certain products <br></br>
 may not be available for online purchase or may be available in our retail stores.
<li> <b>How should I place an order online?</b></li>
Please follow these simple steps:<br></br>
1.Login to the Infispec ecommerce website ,Select the piece of jewellery you want and click on 'Add to cart'<br></br>
2.Click on 'cart' section if you have finalized your order<br></br>
3.Check all the details of the product including the price.<br></br>
4. Enter your shipping details and continue to the Payment Options<br></br>
5.Select your Mode of Payment and Place the Order<br></br>
6.You will receive an order confirmation email to the e-mail you provided while 
placing the order

<li><b>Can I order by phone?</b></li>
          Yes. Visit the website, select the design you want, and place an order by phone.    
<li><b>Will I get an order confirmation? </b> </li>
 Yes. Your order confirmation will be sent to the e-mail you provide while placing your order.

<li> <b>How to Cancel an Order? </b></li>
Please contact our Team at 91-9790333937 to cancel your orders.

<li><b>How do I track my order after it has been shipped? </b></li>
Please log in to your Infispec e-commerce account and track your order in
 the 'Order' section of your account.
<li> <b>What is the estimated delivery time? </b> </li>
Our delivery date depends on the product that you select. Normally we deliver
 products within 10 working days. These 10 working days do not include Saturdays, 
 Sundays and other festive holidays.
<li> <b>Is there any extra charge to be incurred due to Cash on Delivery? </b> </li>
We do not charge any extra amount for COD service.


<li> <b>Can I enroll online in the Easy Gold Scheme? </b></li>
Yes, you can enroll into our website for Easy Gold Scheme and open a new account online using our Infispec E-commerce website.

<li> <b>What happens if my payment fails? </b> </li>
If your payment fails for any reason, you'll need to try to pay again after some time. Infispec will not be responsible for any payment failure due to any reason.


        </ol>
      
    </div>
  )
}

export default FAQ
