import React, { useState } from 'react';

const ContactForm = () => {
  const [formStatus, setFormStatus] = useState('Send');

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormStatus('Submitting...');

    // Simulate form submission process
    setTimeout(() => {
      alert('Form submitted successfully!'); // Show pop-up message

      // Reset the form
      e.target.reset();

      // Reset the form status button text
      setFormStatus('Send');
    }, 1000);
  };

  return (
    <div style={styles.formContainer}>
      <form onSubmit={handleSubmit} style={styles.form}>
        <h2>Contact Form</h2>

        {/* Name Field */}
        <div style={styles.formGroup}>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" required style={styles.input} />
        </div>

        {/* Email Field */}
        <div style={styles.formGroup}>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" required style={styles.input} />
        </div>

        {/* Order ID Field */}
        <div style={styles.formGroup}>
          <label htmlFor="orderId">Order ID:</label>
          <input type="text" id="orderId" name="orderId" required style={styles.input} />
        </div>

        {/* Issues Dropdown */}
        <div style={styles.formGroup}>
          <label htmlFor="issue">Issues:</label>
          <select id="issue" name="issue" required style={styles.input}>
            <option value="">Select an issue</option>
            <option value="payment">Payment Incomplete</option>
            <option value="productInfo">Product Information Missing</option>
            <option value="orderFailed">Order Failed</option>
            <option value="damagedProduct">Product Damaged</option>
            <option value="notDelivered">Product Not Delivered</option>
          </select>
        </div>

        {/* Message Field */}
        <div style={styles.formGroup}>
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" required style={styles.textarea}></textarea>
        </div>

        {/* Submit Button */}
        <button type="submit" style={styles.button}>{formStatus}</button>
      </form>
    </div>
  );
};

const styles = {
  formContainer: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    marginBottom: '15px',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    height: '100px',
  },
  button: {
    backgroundColor: 'black',
    color: '#fff',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
};

export default ContactForm;
