// couponConstants.js

export const COUPON_CREATE_REQUEST = 'COUPON_CREATE_REQUEST';
export const COUPON_CREATE_SUCCESS = 'COUPON_CREATE_SUCCESS';
export const COUPON_CREATE_FAIL = 'COUPON_CREATE_FAIL';

export const COUPON_LIST_REQUEST = 'COUPON_LIST_REQUEST';
export const COUPON_LIST_SUCCESS = 'COUPON_LIST_SUCCESS';
export const COUPON_LIST_FAIL = 'COUPON_LIST_FAIL';

export const COUPON_UPDATE_REQUEST = 'COUPON_UPDATE_REQUEST';
export const COUPON_UPDATE_SUCCESS = 'COUPON_UPDATE_SUCCESS';
export const COUPON_UPDATE_FAIL = 'COUPON_UPDATE_FAIL';

export const COUPON_DELETE_REQUEST = 'COUPON_DELETE_REQUEST';
export const COUPON_DELETE_SUCCESS = 'COUPON_DELETE_SUCCESS';
export const COUPON_DELETE_FAIL = 'COUPON_DELETE_FAIL';

export const COUPON_VALIDATE_SUCCESS= 'COUPON_VALIDATE_SUCCESS'
export const COUPON_VALIDATE_REQUEST= 'COUPON_VALIDATE_REQUEST'
export const COUPON_VALIDATE_FAIL='COUPON_VALIDATE_FAIL'

export const VALIDATE_COUPON_FAIL='VALIDATE_COUPON_FAIL'
export const VALIDATE_COUPON_REQUEST='VALIDATE_COUPON_REQUEST'
export const VALIDATE_COUPON_SUCCESS='VALIDATE_COUPON_SUCCESS'



 // Add more constants as needed for update, delete, etc.
